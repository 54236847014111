// DailyDataTable.tsx
import React from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface DailyData {
  date: string;
  producao_bruta: number;
}

interface  DailyPartnerTyping {
  data: DailyData[];
}

const DailyPartnerTyping: React.FC< DailyPartnerTyping> = ({ data }) => {
  const formatDateUTC = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Mês é zero-indexado
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value: number) => {
    if (value == null || isNaN(value)) return "-"; // Retorna um traço para valores inválidos
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const renderPercentageChange = (current: number, previous: number) => {
    if (previous === 0) {
      return <span className="text-muted">-</span>;
    }

    const change = ((current - previous) / previous) * 100;
    const isImproved = change > 0;

    return (
      <span>
        {isImproved ? (
          <FontAwesomeIcon icon={faArrowUp} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="text-danger" />
        )}{" "}
        {change.toFixed(2)}%
      </span>
    );
  };

  // Ordenando os dados de forma crescente pela data
  const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Dia</th>
          <th>Valor Bruto</th>
          <th>Comparação</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(sortedData) &&
          sortedData.map((day, index) => (
            <tr key={index}>
              <td>{formatDateUTC(day.date)}</td>
              <td>{formatCurrency(day.producao_bruta)}</td>
              <td>
                {index > 0 &&
                typeof day.producao_bruta === "number" &&
                typeof sortedData[index - 1]?.producao_bruta === "number"
                  ? renderPercentageChange(
                      day.producao_bruta,
                      sortedData[index - 1].producao_bruta
                    )
                  : <span className="text-muted">-</span>}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default DailyPartnerTyping;