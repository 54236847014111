import React, { PureComponent } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import styles from '../CardsProjecao.module.scss';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import SpinnerVision from '../../Spinner';

interface ProducaoBanco {
  mesBase: string;
  producaoTotal: number;
  meta: number;
  atingimento: number;
}

interface ProducaoAnualBancoState {
  data: ProducaoBanco[];
  isLoading: boolean;
}

const currencyFormatter = (value: number) => {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const percentFormatter = (value: number) => {
  return `${value.toFixed(2)}%`;
};

export default class ProducaoAnualRegional extends PureComponent<{}, ProducaoAnualBancoState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const codRegional = localStorage.getItem('codRegional');
    const currentYear = new Date().getFullYear().toString();

    try {
      const response = await fetch(`https://bi.gfttech.com.br/api/v2/regional/producaoVsMeta?codRegional=${codRegional}`);
      const responseData = await response.json();

      // Filtrar dados a partir do ano atual e ordenar por mesBase
      const filteredData = responseData
        .filter((item: any) => item.base.startsWith(currentYear))
        .sort((a: any, b: any) => a.base.localeCompare(b.base));

      // Mapear os dados recebidos para o formato esperado pelo componente
      const data: ProducaoBanco[] = filteredData.map((item: any) => ({
        mesBase: item.base,
        producaoTotal: item.producao || 0,
        meta: item.meta || 0,
        atingimento: item.percentual_atingido || 0,
      }));

      this.setState({ data, isLoading: false });
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { data, isLoading } = this.state;

    return (
      <Card>
        <Card.Header>
          <h5 className={`${styles.titleCard} display-6`}>
            <b>Produção Vs Meta Anual</b>
          </h5>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <SpinnerVision/>
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 80,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="mesBase" />
                <YAxis yAxisId="left" tickFormatter={currencyFormatter} />
                <YAxis yAxisId="right" orientation="right" tickFormatter={percentFormatter} />
                <Tooltip formatter={(value: number | string, name: string) => {
                  if (name === "Atingimento (%)") {
                    return [percentFormatter(value as number), name];
                  }
                  return [currencyFormatter(value as number), name];
                }} />
                <Legend />
                <Bar yAxisId="left" dataKey="producaoTotal" name="Produção Total" barSize={40} fill="#032064" />
                <Bar yAxisId="left" dataKey="meta" name="Meta" barSize={40} fill="#006AD8" />
                <Line yAxisId="right" type="monotone" dataKey="atingimento" name="Atingimento (%)" stroke="#FFA500" strokeWidth={2} strokeDasharray="0 0" />
              </ComposedChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </Card>
    );
  }
}
