import React, { useState, useEffect } from 'react';
import { Card, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveContainer } from 'recharts';
import SpinnerVision from '../../Spinner';

// Interface para os dados de proposta
interface Proposta {
  codProdutorVenda: number;
  nomeProdutorVenda: string;
  indiceCanceladas: number;
}

const RankingIndiceCanceladas: React.FC = () => {
  const [data, setData] = useState<Proposta[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));
  const [selectedPartner, setSelectedPartner] = useState<string | null>(localStorage.getItem("selectedPartner"));
  const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codSupervisor'));
  const [intervalId, setIntervalId] = useState<any | null>(null);

  // Verifica e atualiza as variáveis a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      const newBase = localStorage.getItem("selectedBase");
      const newCodSupervisor = localStorage.getItem('codSupervisor');
      const newSelectedPartner = localStorage.getItem('selectedPartner');

      if (newBase !== selectedBase) {
        setSelectedBase(newBase);
      }
      if (newCodSupervisor !== codSupervisor) {
        setCodSupervisor(newCodSupervisor);
      }
      if (newSelectedPartner !== selectedPartner) {
        setSelectedPartner(newSelectedPartner);
      }
    }, 1000); // Verifica a cada 1 segundo

    setIntervalId(interval);
    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [selectedBase, codSupervisor, selectedPartner]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (selectedBase && codSupervisor) {
          let url = `https://bi.gfttech.com.br/api/v1/parceiro/indices?base=${selectedBase}&codSupervisor=${codSupervisor}`;
          if (selectedPartner) {
            url += `&codProdutorVenda=${selectedPartner}`;
          }
          const response = await fetch(url);
          const responseData = await response.json();

          // Mapear os dados recebidos para o formato esperado pelo componente
          const mappedData: Proposta[] = responseData.map((item: any) => ({
            codProdutorVenda: item.codProdutorVenda,
            nomeProdutorVenda: item.nomeProdutorVenda,
            indiceCanceladas: item.indiceCanceladas,
          }));

          // Ordenar o array do maior para o menor índice de canceladas
          const sortedData = mappedData.sort((a, b) => b.indiceCanceladas - a.indiceCanceladas);

          setData(sortedData);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedBase, codSupervisor, selectedPartner, intervalId]);

  return (
    <>
      <Card className='mt-5'>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <span>Ranking Parceiros - Índice Canceladas</span>
          <FontAwesomeIcon icon={faArrowDown} style={{ color: 'red' }} />
        </Card.Header>
        <Card.Body style={{ paddingBottom: "30px" }}>
          
          <ResponsiveContainer width="100%" height="100%">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <SpinnerVision/>
              </div>
            ) : (
              <div style={{ minHeight: "300px", maxHeight: "300px", overflowY: "auto" }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Parceiro</th>
                      <th>Índice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((proposta, index) => (
                      <tr key={index}>
                        <td>{proposta.codProdutorVenda} - {proposta.nomeProdutorVenda}</td>
                        <td>{proposta.indiceCanceladas}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </ResponsiveContainer>
          
        </Card.Body>
      </Card>
    </>
  );
};

export default RankingIndiceCanceladas;
