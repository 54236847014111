import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteComercial";
import FilterComercial from "../../components/FilterComercial";
import DataAgentesProd from "../../components/Graficos/DataAgentesProd";
import RankingAgentes from "../../components/Graficos/RankingAgentes";
import RankingSubs from "../../components/Graficos/RankingSubs";
import { motion } from 'framer-motion';
import logoVisionBi from '../../assets/images/logo-vision-bi.png'
import styles from './Styling.module.scss'

export default function AgentAnalytics() {
  const [base, setBase] = useState<string | null>(null);
  const [partner, setPartner] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  useEffect(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    setPartner(storedPartner);
  }, []);

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <DataAgentesProd />
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card>
                <Card.Header>Ranking - Top 10 Produção Agentes</Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <RankingAgentes base={base} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card>
                <Card.Header>Ranking - Top 10 Produção Substabelecidos</Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <RankingSubs base={base} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  )
}
