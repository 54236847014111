import React from "react";
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";

interface DadosGraficoPizzaProps {
  producaoAgente: number;
  producaoSubstabelecido: number;
}

const AgenteSub: React.FC<DadosGraficoPizzaProps> = ({ producaoAgente, producaoSubstabelecido }) => {
  // Definindo os dados para o gráfico
  const data = [
    { name: "Agente", value: producaoAgente },
    { name: "Substabelecido", value: producaoSubstabelecido },
  ];

  // Calculando a produção total
  const totalProducao = producaoAgente + producaoSubstabelecido;

  // Definindo cores para as fatias
  const colors = ["#062173", "#0F6DFC"];

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value).replace("R$", "").trim();
  };

  // Função personalizada para renderizar as labels
  const renderCustomLabel = (entry: any) => {
    const { name, value } = entry;
    const percent = ((value / totalProducao) * 100).toFixed(2);
    return `${percent}%`;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={400} height={300} margin={{ left: 50}}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={renderCustomLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value: number) => formatCurrency(value)} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default AgenteSub;
