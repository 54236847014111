import { useState } from "react";
import axios from "axios";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonPrimary from "../../components/ButtonPrimary";
import styles from './FirstAccess.module.scss'

interface FirstAccessProps {
  userId: number;
}

export default function FirstAccess({ userId }: FirstAccessProps) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const isFormValid = () => {
    return newPassword.trim() !== '' && confirmPassword.trim() !== '' && newPassword === confirmPassword;
  };

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token de autenticação não encontrado.');
      return;
    }

    try {
        await axios.put(
            `https://webservices.gfttech.com.br/api/v1/usuario/atualizar-senha/${userId}`,
        {
            old_password: "gft@2024",
            password: newPassword,
            password_confirmation: confirmPassword
        },
        {
            headers: {
            Authorization: `Bearer ${token}`
            }
        }
        );

      setSuccessMessage("Senha atualizada com sucesso! Gentileza efetuar login.");
      setTimeout(() => {
        window.location.href = "/";
      }, 3000); // Redireciona após 3 segundos
    } catch (error) {
      setError('Erro ao atualizar a senha. Por favor, tente novamente.');
    }
  };

  return (
    <div >
      <h4 className={`${styles.titleFirstAccess} mb-5 text-center`}>Primeiro Acesso</h4>
      <FloatingLabel controlId="floatingNewPassword" label="Nova Senha" className="mb-4">
        <Form.Control
          type="password"
          placeholder="Nova Senha"
          value={newPassword}
          onChange={handleNewPasswordChange}
          className="form-control"
        />
      </FloatingLabel>
      <FloatingLabel controlId="floatingConfirmPassword" label="Confirmar Senha" className="mb-5">
        <Form.Control
          type="password"
          placeholder="Confirmar Senha"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          className="form-control"
        />
      </FloatingLabel>
      {error && <p className="text-danger text-center">{error}</p>}
      {successMessage && <p className="text-success text-center">{successMessage}</p>}
      <div className="d-flex justify-content-center mt-4">
        <ButtonPrimary
            content="Redefinir Senha"
            onClick={handlePasswordReset}
            
        />
      </div>
      
    </div>
  );
}
