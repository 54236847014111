import React, { useState } from 'react';
import { Modal, Table, Nav, Tab } from 'react-bootstrap';
import styles from './ComparativoMeses.module.scss';
import { ProducaoMes } from '../../../types/types';

// Tipagem das props do modal
interface ComparativoProducaoModalProps {
  show: boolean;
  onHide: () => void;
  dadosProducao: ProducaoMes[];
}

// Função para formatar os valores monetários
const formatarMoeda = (valor: number) => {
  return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

// Função para calcular o crescimento em %
const calcularCrescimento = (producaoAtual: number | null, producaoAnterior: number | null) => {
  if (producaoAnterior === null || producaoAnterior === 0) return '0%';
  const crescimento = ((producaoAtual ?? 0) - producaoAnterior) / producaoAnterior * 100;
  return crescimento.toFixed(2) + '%';
};

// Função para calcular a produção total de um ano
const calcularProducaoTotalPorAno = (dados: ProducaoMes[], ano: string) => {
  return dados
    .filter(dado => dado.mes_base.startsWith(ano)) // Acessando o campo `mes_base`
    .reduce((total, dado) => total + (dado.producao ?? 0), 0); // Acessando o campo `producao`
};

// Função para calcular a projeção do próximo ano
const calcularProjecao = (producaoAtual: number, producaoAnterior: number) => {
  const crescimento = ((producaoAtual - producaoAnterior) / producaoAnterior);
  return producaoAtual * (1 + crescimento);
};

const ComparativoProducaoModal: React.FC<ComparativoProducaoModalProps> = ({ show, onHide, dadosProducao }) => {
  const [anoSelecionado, setAnoSelecionado] = useState(new Date().getFullYear().toString());

  // Filtra os anos únicos
  const anosDisponiveis = Array.from(new Set(dadosProducao.map(dado => dado.mes_base.substring(0, 4)))); // Usando `mes_base`

  return (
    <Modal show={show} className={styles.modalGeral} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Análise - Evolução da Produção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container activeKey={anoSelecionado} onSelect={(k) => setAnoSelecionado(k || anoSelecionado)}>
          <Nav variant="pills" className="mb-3">
            {anosDisponiveis.map(ano => (
              <Nav.Item key={ano}>
                <Nav.Link eventKey={ano}>{ano}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            {anosDisponiveis.map(ano => {
              const producaoAnoAtual = calcularProducaoTotalPorAno(dadosProducao, ano);
              const producaoAnoAnterior = calcularProducaoTotalPorAno(dadosProducao, (parseInt(ano) - 1).toString());
              const projecaoAnoSeguinte = calcularProjecao(producaoAnoAtual, producaoAnoAnterior);

              return (
                <Tab.Pane eventKey={ano} key={ano}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th>Produção (producao)</th>
                        <th>Crescimento (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dadosProducao
                        .filter(dado => dado.mes_base.startsWith(ano)) // Filtra usando `mes_base`
                        .map((dado, index, dadosFiltrados) => {
                          let producaoAnterior: number | null = null;

                          // Obter a produção do mês anterior
                          if (index > 0) {
                            const mesAnterior = (parseInt(dado.mes_base.substring(4)) - 1).toString().padStart(2, '0');
                            const anoAnterior = dado.mes_base.substring(0, 4);
                            const dadoAnterior = dadosProducao.find(d => d.mes_base === anoAnterior + mesAnterior);
                            producaoAnterior = dadoAnterior ? dadoAnterior.producao : null; // Usando `producao`
                          }

                          const crescimento = calcularCrescimento(dado.producao, producaoAnterior);

                          return (
                            <tr key={dado.mes_base}>
                              <td>{dado.mes_base}</td> {/* Exibindo `mes_base` conforme formatado */}
                              <td>{formatarMoeda(dado.producao ?? 0)}</td> {/* Acessando `producao` */}
                              <td
                                style={{
                                  color: crescimento.includes('-') ? 'red' : 'green', // Cor vermelha para valores negativos
                                }}
                              >
                                {producaoAnterior !== null ? crescimento : ''}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>

                  {producaoAnoAnterior > 0 && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Produção {parseInt(ano) - 1}: </th>
                          <th>Produção {parseInt(ano)}: </th>
                          <th>Crescimento (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{formatarMoeda(producaoAnoAnterior)}</td>
                          <td>{formatarMoeda(producaoAnoAtual)}</td>
                          <td
                            style={{
                              color: calcularCrescimento(producaoAnoAtual, producaoAnoAnterior).includes('-') ? 'red' : 'green',
                            }}
                          >
                            {calcularCrescimento(producaoAnoAtual, producaoAnoAnterior)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}

                  {anoSelecionado === new Date().getFullYear().toString() && producaoAnoAtual > producaoAnoAnterior && (
                    <div style={{ backgroundColor: '#f0f8ff', padding: '15px', borderRadius: '5px', marginTop: '10px' }}>
                      <h6>Projeção para {parseInt(ano) + 1}:</h6>
                      <p>
                        <b className='me-2'>Se o crescimento continuar nessa média, poderá atingir:</b>
                        <strong style={{ color: 'orange' }}>
                          {formatarMoeda(projecaoAnoSeguinte)}
                        </strong>
                      </p>
                    </div>
                  )}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default ComparativoProducaoModal;
