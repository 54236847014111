import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

interface Proposta {
    COD_PROPOSTA_EMPRESTIMO: number;
    NUM_PROPOSTA: string;
    Id_Parceiro: number;
    Nome_Parceiro: string;
    val_bruto: number;
}

const DataAgentesProdParceiro: React.FC = () => {
    const [agentData, setAgentData] = useState<{ Id_Parceiro: number; Nome_Parceiro: string; totalProducao: number }[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [partnerState, setPartnerState] = useState<string | null>(localStorage.getItem('selectedPartner'));
    const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codIndicador'));

    // Função para atualizar estados baseado no localStorage
    const updateStatesFromLocalStorage = () => {
        const storedBase = localStorage.getItem('selectedBase');
        const storedPartner = localStorage.getItem('selectedPartner');
        const storedCodSupervisor = localStorage.getItem('codIndicador');

        if (storedBase !== baseState) {
            setBaseState(storedBase);
        }
        if (storedPartner !== partnerState) {
            setPartnerState(storedPartner);
        }
        if (storedCodSupervisor !== codSupervisor) {
            setCodSupervisor(storedCodSupervisor);
        }
    };

    // Efeito para buscar dados quando baseState, partnerState ou codSupervisor mudam
    useEffect(() => {
        const fetchData = async () => {
            if (codSupervisor && baseState) {
                try {
                    let url = `https://bi.gfttech.com.br/api/v2/indicador/producao?codSupervisor=${codSupervisor}&base=${baseState}`;

                    if (partnerState) {
                        url += `&codProdutorVenda=${partnerState}`;
                    }

                    const response = await fetch(url);
                    const data: Proposta[] = await response.json();

                    // Agrupando os dados por Id_Parceiro
                    const groupedData: { [key: number]: { Id_Parceiro: number; Nome_Parceiro: string; totalProducao: number } } = {};
                    data.forEach((proposta) => {
                        if (!(proposta.Id_Parceiro in groupedData)) {
                            groupedData[proposta.Id_Parceiro] = {
                                Id_Parceiro: proposta.Id_Parceiro,
                                Nome_Parceiro: proposta.Nome_Parceiro,
                                totalProducao: 0
                            };
                        }
                        groupedData[proposta.Id_Parceiro].totalProducao += proposta.val_bruto;
                    });

                    // Convertendo o objeto de mapa em um array de valores e ordenando
                    const aggregatedData = Object.values(groupedData);
                    const sortedData = aggregatedData.sort((a, b) => b.totalProducao - a.totalProducao);

                    setAgentData(sortedData);
                } catch (error) {
                    console.error("Erro ao buscar dados dos agentes:", error);
                }
            }
        };

        fetchData();
    }, [codSupervisor, baseState, partnerState]);

    // Efeito para verificar mudanças no localStorage a cada segundo
    useEffect(() => {
        const interval = setInterval(() => {
            updateStatesFromLocalStorage();
        }, 1000); // Intervalo de verificação a cada segundo

        return () => clearInterval(interval);
    }, []); // Sem dependências, executa apenas uma vez ao montar o componente

    return (
        <div style={{ maxHeight: "580px", overflowY: "auto" }}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id Parceiro</th>
                        <th>Nome Parceiro</th>
                        <th>Produção Total</th>
                    </tr>
                </thead>
                <tbody>
                    {agentData.map((agent) => (
                        <tr key={agent.Id_Parceiro}>
                            <td>{agent.Id_Parceiro}</td>
                            <td>{agent.Nome_Parceiro}</td>
                            <td>{agent.totalProducao !== 0 ? agent.totalProducao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DataAgentesProdParceiro;
