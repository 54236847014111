import { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from 'framer-motion';
import logoVisionBi from '../../../assets/images/logo-vision-bi.png'
import styles from './Styling.module.scss'
import FilterComercial from "../../../components/FilterComercial";
import DataAgentesProdParceiro from "../../../components/GraficosParceiros/DataAgentesProdParceiro";
import NavBarIndicador from "../../../components/NavBar/Indicador";

export default function AgentAnalyticsIndicador() {
  const [base, setBase] = useState<string | null>(null);
  const [partner, setPartner] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  useEffect(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    setPartner(storedPartner);
  }, []);

  return (
    <>
      <NavBarIndicador />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <DataAgentesProdParceiro />
          </Row>
        </Container>
      </motion.div>
    </>
  )
}
