import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { format } from "date-fns";
import logoVisionBi from '../../../assets/images/logo-vision-bi.png';
import styles from './Styling.module.scss';
import ProducaoConvenioBar from "../../../components/Graficos/ProducaoConvenioBar";
import ProducaoOperacao from "../../../components/Graficos/ProducaoOperacao";
import ProducaoTabela from "../../../components/Graficos/ProducaoLinha";
import NavBarIndicador from "../../../components/NavBar/Indicador";
import FilterComercial from "../../../components/FilterComercial";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faChartLine, faDollar, faHandHoldingDollar, faMoneyBill, faPlus } from "@fortawesome/free-solid-svg-icons";
import BrutoLiquidoParceiro from "../../../components/GraficosParceiros/BrutoLiquido";
import ComparativoProducaoModal from "../../../components/GraficosParceiros/ComparativoMeses";
import axios from "axios";
import ComissaoRecebidaModal from "../../../components/ModalsParceiro/ComissaoRecebida";

interface ProducaoMes {
  mes_ano: string;
  BRUTO_INDICADOR: number;
}


export default function HomeIndicador() {
  const [baseSelecionada, setBaseSelecionada] = useState<string>(format(new Date(), 'yyyyMM'));
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCmsRecebida, setShowModalCmsRecebida] = useState(false);
  const [dadosProducao, setDadosProducao] = useState<ProducaoMes[]>([]);

  const [producaoBruta, setProducaoBruta] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [producaoLiquida, setProducaoLiquida] = useState<number>(0);
  const [producaoPorConvenio, setProducaoPorConvenio] = useState<any[]>([]);
  const [producaoSemanalArray, setProducaoSemanalArray] = useState<any[]>([]);
  const [operacoesArray, setProducaoPorOperacao] = useState<any[]>([]);
  const [comparativoBruto, setComparativoBruto] = useState<number | null>(null);
  const codIndicador = localStorage.getItem('codIndicador')


  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openModalCmsRecebida = () => setShowModalCmsRecebida(true);
  const closeModalCmsRecebida = () => setShowModalCmsRecebida(false);

  const mockPropostas = [
    {
      id: 101,
      dataPagamento: '15/10/2024',
      valorTotal: 1200.5,
      detalhes: [
        { descricao: 'Ad. Dif', valor: 800 },
        { descricao: 'Diferido', valor: 400.5 },
      ],
      banco: "C6 Bank"
    },
    {
      id: 102,
      dataPagamento: '18/10/2024',
      valorTotal: 560.0,
      detalhes: [
        { descricao: 'Ad. Dif', valor: 600 },
        { descricao: 'Diferido', valor: 300 },
      ],
      banco: "Banco Pan"
    },
    {
      id: 103,
      dataPagamento: '10/10/2024',
      valorTotal: 987.45,
      detalhes: [
        { descricao: 'Ad. Dif', valor: 600 },
        { descricao: 'Diferido', valor: 300 },
      ],
      banco: "C6 Bank"
    },
    {
      id: 104,
      dataPagamento: '19/10/2024',
      valorTotal: 989.9,
      detalhes: [
        { descricao: 'Ad. Dif', valor: 600 },
        { descricao: 'Diferido', valor: 300 },
      ],
      banco: "Banco Pan"
    },
    {
      id: 105,
      dataPagamento: '02/10/2024',
      valorTotal: 120.5,
      detalhes: [
        { descricao: 'Ad. Dif', valor: 600 },
        { descricao: 'Diferido', valor: 300 },
      ],
      banco: "C6 Bank"
    },
  ];
  
  useEffect(() => {
    async function fetchProducaoData() {
      try {
        const response = await fetch(`https://bi.gfttech.com.br/api/v2/indicador/producao?base=${baseSelecionada}&codSupervisor=5470`);
        const data = await response.json();
  
        // Calcular Produção Bruta e Líquida
        let brutoTotal = 0;
        let liquidoTotal = 0;
  
        // Preparar dados para Produção por Convênio
        const convenioMap: { [key: string]: number } = {};
  
        // Preparar dados para Produção por Operação
        const operacaoMap: { [key: string]: number } = {};
  
        // Preparar dados para Produção Semanal
        const semanasMap: { [key: string]: { val_bruto: number; count: number } } = {};
  
        data.forEach((item: any) => {
          brutoTotal += item.val_bruto;
          liquidoTotal += item.val_repasse;
  
          // Agrupar Produção por Convênio
          if (convenioMap[item.DSC_GRUPO]) {
            convenioMap[item.DSC_GRUPO] += item.val_bruto;
          } else {
            convenioMap[item.DSC_GRUPO] = item.val_bruto;
          }
  
          // Agrupar Produção por Operação
          if (operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO]) {
            operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO] += item.val_bruto;
          } else {
            operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO] = item.val_bruto;
          }
  
          // Agrupar Produção por Semana (baseada na data de confirmação)
          const semana = format(new Date(item.dat_confirmacao), 'I');
          if (semanasMap[semana]) {
            semanasMap[semana].val_bruto += item.val_bruto;
            semanasMap[semana].count += 1;
          } else {
            semanasMap[semana] = { val_bruto: item.val_bruto, count: 1 };
          }
        });
  
        setProducaoBruta(brutoTotal);
        setProducaoLiquida(liquidoTotal);
  
        // Formatar dados de Produção por Convênio para o gráfico
        const conveniosArray = Object.keys(convenioMap).map((key) => ({
          convenio: key,
          val_bruto: convenioMap[key],
        }));
        setProducaoPorConvenio(conveniosArray);
  
        // Formatar dados de Produção por Operação para o gráfico
        const operacoesArray = Object.keys(operacaoMap).map((key) => ({
          operacao: key,
          val_bruto: operacaoMap[key],
        }));
        setProducaoPorOperacao(operacoesArray); // Definir estado para Produção por Operação
  
        // Formatar dados de Produção Semanal para o gráfico
        const semanasArray = Object.keys(semanasMap).map((key, index) => ({
          weekLabel: `Semana ${index + 1}`,
          val_bruto: semanasMap[key].val_bruto,
          growth: index === 0 ? '' : (((semanasMap[key].val_bruto - semanasMap[Object.keys(semanasMap)[index - 1]].val_bruto) / semanasMap[Object.keys(semanasMap)[index - 1]].val_bruto) * 100).toFixed(2),
        }));
        setProducaoSemanalArray(semanasArray);
  
      } catch (error) {
        console.error("Erro ao buscar dados de produção:", error);
      }
    }
  
    fetchProducaoData();
  }, [baseSelecionada]);

  const projecao = 12000;

  useEffect(() => {
    axios.get(`https://bi.gfttech.com.br/api/v2/indicador/producaoHistoryMonthly?codSupervisor=${codIndicador}`)
      .then(response => {
        const dados = response.data;
  
        // Agrupar Produção por mes_ano e somar BRUTO_INDICADOR
        const mesAnoMap: { [key: string]: number } = {};
  
        dados.forEach((item: ProducaoMes) => {
          const mesAnoKey = item.mes_ano; // Assume que mes_ano é a chave correta
          if (mesAnoMap[mesAnoKey]) {
            mesAnoMap[mesAnoKey] += item.BRUTO_INDICADOR; // Soma os BRUTO_INDICADOR
          } else {
            mesAnoMap[mesAnoKey] = item.BRUTO_INDICADOR; // Inicializa a soma
          }
        });
  
        // Converter o mapa de volta para um array se necessário
        const dadosAgrupados = Object.keys(mesAnoMap).map(key => ({
          mes_ano: key,
          BRUTO_INDICADOR: mesAnoMap[key],
        }));
  
        setDadosProducao(dadosAgrupados);
  
        // Verificar o mês atual e o mês anterior
        const mesAtual = dadosAgrupados.find((item) => item.mes_ano === baseSelecionada);
        const mesAnterior = dadosAgrupados.find((item) => item.mes_ano === format(new Date(new Date().setMonth(new Date().getMonth() - 1)), 'yyyyMM'));
  
        if (mesAtual && mesAnterior) {
          const valorAtual = mesAtual.BRUTO_INDICADOR; // Usar BRUTO_INDICADOR agregado
          const valorAnterior = mesAnterior.BRUTO_INDICADOR; // Usar BRUTO_INDICADOR agregado
  
          if (valorAnterior !== 0) {
            const crescimentoPercentual = ((valorAtual - valorAnterior) / valorAnterior) * 100;
            setComparativoBruto(crescimentoPercentual);
          } else {
            setComparativoBruto(0);
          }
        }
      })
      .catch(error => {
        setError('Erro ao buscar dados de produção.');
        console.error('Erro ao buscar dados de produção:', error);
      });
  }, [baseSelecionada, codIndicador]);
  


  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  return (
    <>
      <NavBarIndicador />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1.5 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{ maxWidth: '270px' }} alt="Logo Vision BI" />
            </div>
          </Row>
          <Row>
            <FilterComercial onBaseChange={(base: string) => setBaseSelecionada(base)} />
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={12} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card className="p-4">
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} className="mt-3">
                          <Card className={styles.cardProduction} onClick={openModal}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className={styles.spanCard}>
                                    <FontAwesomeIcon className="me-1" icon={faDollar} />
                                  </span>
                                  <span className={`${styles.spanCard} me-3`}>Produção:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span className={`${styles.numberSmall} me-2`}>R$ {producaoBruta.toLocaleString()}</span>
                                  <span style={{ fontSize: 10, fontWeight: 'bold', color: comparativoBruto !== null && comparativoBruto < 0 ? '#FF0000' : '#7CFC00' }}>
                                    <FontAwesomeIcon icon={comparativoBruto !== null && comparativoBruto < 0 ? faCaretDown : faCaretUp} />
                                    {comparativoBruto !== null ? `${Math.floor(comparativoBruto)}` : ''}
                                  </span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={12} md={6} className="mt-3">
                          <Card className={styles.cardProjection}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className={styles.spanCard}>
                                    <FontAwesomeIcon className="me-2" icon={faChartLine} />
                                  </span>
                                  <span className={styles.spanCard}>Projeção:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span className={styles.numberSmall}>R$ {projecao}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>

                      <Row className="mt-5">
                        <Col sm={12} md={6}>
                          <Card>
                            <Card.Body>
                              <BrutoLiquidoParceiro producaoBruta={producaoBruta} producaoLiquida={producaoLiquida} />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={12} md={6}>
                          {/* Comissão Total */}
                          <Card className="bg-success mt-4" onClick={openModalCmsRecebida} style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faHandHoldingDollar} />
                                  </span>
                                  <span>Comissão Total:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>R$ 150000</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* A Receber */}
                          <Card className="bg-secondary mt-4" style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                  </span>
                                  <span>A Receber:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>R$ 20000</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* Bônus */}
                          <Card className="bg-warning mt-4">
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                  </span>
                                  <span>Bônus:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>R$ 5000</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* Estornos */}
                          <Card className="bg-danger mt-4" style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                  </span>
                                  <span>Estornos:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>- R$ 3000</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>

                      </Row>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>

          {/* Produção - Convênios and Operações */}
          <Row className="mt-3">
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Convênios</Card.Header>
                    <Card.Body>
                      <ProducaoConvenioBar data={producaoPorConvenio} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Operações</Card.Header>
                    <Card.Body>
                      <ProducaoOperacao data={operacoesArray}/>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>

          {/* Evolução Semanal */}
          <Row className="mt-3">
            <Col xs={12} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Evolução por Semana</Card.Header>
                    <Card.Body>
                      <ProducaoTabela data={producaoSemanalArray} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>
        </Container>

        {/* Modals */}
        {/* <ComparativoProducaoModal
          show={showModal}
          onHide={closeModal}
          dadosProducao={dadosProducao}
        />
        <ComissaoRecebidaModal 
          show={showModalCmsRecebida} 
          onHide={closeModalCmsRecebida} 
          propostas={mockPropostas} 
        /> */}

      </motion.div>
    </>
  );
}
