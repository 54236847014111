import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { format } from "date-fns";
import logoVisionBi from '../../../assets/images/logo-vision-bi.png';
import styles from './Styling.module.scss';
import ProducaoConvenioBar from "../../../components/Graficos/ProducaoConvenioBar";
import ProducaoOperacao from "../../../components/Graficos/ProducaoOperacao";
import ProducaoTabela from "../../../components/Graficos/ProducaoLinha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faChartLine, faDollar, faHandHoldingDollar, faMoneyBill, faPlus } from "@fortawesome/free-solid-svg-icons";
import BrutoLiquidoParceiro from "../../../components/GraficosParceiros/BrutoLiquido";
import axios from "axios";
import NavBarParceiro from "../../../components/NavBar/Parceiro";
import FilterParceiro from "../../../components/FilterParceiro";
import ComparativoProducaoModal from "../../../components/GraficosParceiros/ComparativoMeses";
import { ProducaoMes } from "../../../types/types";

interface ComissaoData {
  Comissao_Recebida: number;
  nom_produtor_venda: string;
  Comissao_Geracao_Relatorio: number;
  Comissao_A_Receber: number;
  base: string;
}

interface EstornoData {
  Comissao_Recebida: number;
  base: string;
}
interface BonusData {
  val_baixado: number;
}
interface ProjectionData {
  nom_produtor_venda: string;
  producao_bruta: string;
  media_producao_bruta_diaria: string;
  projecao_producao_bruta: number;
}


export default function HomeParceiro() {
  const [baseSelecionada, setBaseSelecionada] = useState<string>(format(new Date(), 'yyyyMM'));
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [dadosProducao, setDadosProducao] = useState<ProducaoMes[]>([]);

  const [producaoBruta, setProducaoBruta] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [producaoLiquida, setProducaoLiquida] = useState<number>(0);
  const [producaoPorConvenio, setProducaoPorConvenio] = useState<any[]>([]);
  const [producaoSemanalArray, setProducaoSemanalArray] = useState<any[]>([]);
  const [operacoesArray, setProducaoPorOperacao] = useState<any[]>([]);
  const [comparativoBruto, setComparativoBruto] = useState<number | null>(null);
  const [comissaoData, setComissaoData] = useState<ComissaoData | null>(null);
  const [estornoData, setEstornoData] = useState<EstornoData | null>(null);
  const [bonusData, setBonusData] = useState<BonusData | null>(null);
  const [totalBonus, setTotalBonus] = useState<number | null>(null);
  const [projectionData, setProjectionData] = useState<ProjectionData | null>(null);
  const codParceiro = localStorage.getItem('codParceiro')

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const storedBase = localStorage.getItem('selectedBase');

      if (storedBase !== baseSelecionada) {
        setBaseSelecionada(storedBase || format(new Date(), 'yyyyMM'));
      }
     
    }, 1000); // Verifica a cada segundo

    return () => clearInterval(interval);
  }, [baseSelecionada]);
  
  // Producao
  useEffect(() => {
    async function fetchProducaoData() {
      try {

        const response = await fetch(`https://bi.gfttech.com.br/api/v2/agente/producao?base=${baseSelecionada}&codProdutorVenda=${codParceiro}`);
        const data = await response.json();
  
        // Calcular Produção Bruta e Líquida
        let brutoTotal = 0;
        let liquidoTotal = 0;
  
        // Preparar dados para Produção por Convênio
        const convenioMap: { [key: string]: number } = {};
  
        // Preparar dados para Produção por Operação
        const operacaoMap: { [key: string]: number } = {};
  
        // Preparar dados para Produção Semanal
        const semanasMap: { [key: string]: { val_bruto: number; count: number } } = {};
  
        data.forEach((item: any) => {
          brutoTotal += item.val_bruto;
          liquidoTotal += item.val_repasse;
  
          // Agrupar Produção por Convênio
          if (convenioMap[item.DSC_GRUPO]) {
            convenioMap[item.DSC_GRUPO] += item.val_bruto;
          } else {
            convenioMap[item.DSC_GRUPO] = item.val_bruto;
          }
  
          // Agrupar Produção por Operação
          if (operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO]) {
            operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO] += item.val_bruto;
          } else {
            operacaoMap[item.DSC_TIPO_PROPOSTA_EMPRESTIMO] = item.val_bruto;
          }
  
          // Agrupar Produção por Semana (baseada na data de confirmação)
          const semana = format(new Date(item.dat_confirmacao), 'I');
          if (semanasMap[semana]) {
            semanasMap[semana].val_bruto += item.val_bruto;
            semanasMap[semana].count += 1;
          } else {
            semanasMap[semana] = { val_bruto: item.val_bruto, count: 1 };
          }
        });
  
        setProducaoBruta(brutoTotal);
        setProducaoLiquida(liquidoTotal);
  
        // Formatar dados de Produção por Convênio para o gráfico
        const conveniosArray = Object.keys(convenioMap).map((key) => ({
          convenio: key,
          val_bruto: convenioMap[key],
        }));
        setProducaoPorConvenio(conveniosArray);
  
        // Formatar dados de Produção por Operação para o gráfico
        const operacoesArray = Object.keys(operacaoMap).map((key) => ({
          operacao: key,
          val_bruto: operacaoMap[key],
        }));
        setProducaoPorOperacao(operacoesArray); // Definir estado para Produção por Operação
  
        // Formatar dados de Produção Semanal para o gráfico
        const semanasArray = Object.keys(semanasMap).map((key, index) => ({
          weekLabel: `Semana ${index + 1}`,
          val_bruto: semanasMap[key].val_bruto,
          growth: index === 0 ? '' : (((semanasMap[key].val_bruto - semanasMap[Object.keys(semanasMap)[index - 1]].val_bruto) / semanasMap[Object.keys(semanasMap)[index - 1]].val_bruto) * 100).toFixed(2),
        }));
        setProducaoSemanalArray(semanasArray);
  
      } catch (error) {
        console.error("Erro ao buscar dados de produção:", error);
      }
    }
  
    fetchProducaoData();
  }, [baseSelecionada]);

  // Historico producao mensal
  useEffect(() => {
    const fetchComissaoData = async () => {
      try {
        const response = await axios.get(
          'https://bi.gfttech.com.br/api/v2/agente/historicoProducaoMensal?',
          {
            params: {
              codProdutorVenda: codParceiro
            }
          }
        );
        setDadosProducao(response.data);
      } catch (err) {
        setError('Erro ao buscar historico de produção');
      }
      console.log(dadosProducao);
    };

    fetchComissaoData();
  }, [baseSelecionada, codParceiro]);

  // Pegando comissao total e a receber
  useEffect(() => {
    const fetchComissaoData = async () => {
      try {
        const response = await axios.get(
          'https://bi.gfttech.com.br/api/v2/agente/comissao',
          {
            params: {
              codProdutorVenda: codParceiro,
              base: baseSelecionada
            }
          }
        );
        setComissaoData(response.data[0]);
      } catch (err) {
        setError('Erro ao buscar dados de comissão');
      }
    };

    fetchComissaoData();
  }, [baseSelecionada, codParceiro]);

  // Pegando valor total de Estornos
  useEffect(() => {
    const fetchComissaoData = async () => {
      try {
        const response = await axios.get(
          'https://bi.gfttech.com.br/api/v2/agente/estorno?',
          {
            params: {
              codProdutorVenda: codParceiro
            }
          }
        );
        const filteredData = response.data.find((item: EstornoData) => item.base === baseSelecionada);
        setEstornoData(filteredData || null);
      } catch (err) {
        setError('Erro ao buscar dados de comissão');
      }
    };

    fetchComissaoData();
  }, [baseSelecionada, codParceiro]);

  // Pegando valor total de bonus
  useEffect(() => {
    const fetchComissaoData = async () => {
      try {
        const response = await axios.get(
          'https://bi.gfttech.com.br/api/v2/agente/bonus?',
          {
            params: {
              codProdutorVenda: codParceiro,
              base: baseSelecionada
            }
          }
        );
        const data = response.data;
        const total = data.reduce((sum: number, bonus: BonusData) => sum + bonus.val_baixado, 0);
        setBonusData(data);
        setTotalBonus(total);
      } catch (err) {
        setError('Erro ao buscar dados de comissão');
      }
    };

    fetchComissaoData();
  }, [baseSelecionada, codParceiro]);

  // Verificar mês atual
  const currentMonth = new Date().toISOString().slice(0, 7).replace('-', '');

  // Exibir valor com base na seleção
  const displayValue = 
    baseSelecionada === currentMonth && projectionData 
      ? projectionData.projecao_producao_bruta 
      : producaoBruta;

  //Pegando Projeção
  useEffect(() => {
    const fetchProjectionData = async () => {
      try {
        const response = await axios.get(
          'https://bi.gfttech.com.br/api/v2/agente/projecao',
          {
            params: { codProdutorVenda: codParceiro }
          }
        );
        setProjectionData(response.data[0]);
      } catch (err) {
        setError('Erro ao buscar dados de projeção');
      }
    };
  
    fetchProjectionData();
  }, [codParceiro]);

  const formatCurrency = (value: number) => 
    new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  return (
    <>
      <NavBarParceiro />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1.5 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{ maxWidth: '270px' }} alt="Logo Vision BI" />
            </div>
          </Row>
          <Row>
            <FilterParceiro onBaseChange={(base: string) => setBaseSelecionada(base)} />
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={12} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card className="p-4">
                    <Card.Body>
                      <Row>
                        <Col sm={12} md={6} className="mt-3">
                          <Card className={styles.cardProduction} onClick={openModal}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className={styles.spanCard}>
                                    <FontAwesomeIcon className="me-1" icon={faDollar} />
                                  </span>
                                  <span className={`${styles.spanCard} me-3`}>Produção:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span className={`${styles.numberSmall} me-2`}>R$ {producaoBruta.toLocaleString()}</span>
                                  {/* <span style={{ fontSize: 10, fontWeight: 'bold', color: comparativoBruto !== null && comparativoBruto < 0 ? '#FF0000' : '#7CFC00' }}>
                                    <FontAwesomeIcon icon={comparativoBruto !== null && comparativoBruto < 0 ? faCaretDown : faCaretUp} />
                                    {comparativoBruto !== null ? `${Math.floor(comparativoBruto)}` : ''}
                                  </span> */}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={12} md={6} className="mt-3">
                          <Card className={styles.cardProjection}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className={styles.spanCard}>
                                    <FontAwesomeIcon className="me-2" icon={faChartLine} />
                                  </span>
                                  <span className={styles.spanCard}>Projeção:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span className={styles.numberSmall}>
                                    {
                                      <span>{displayValue !== null ? formatCurrency(displayValue) : 'R$ 0,00'}</span>
                                    }
                                  </span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>

                      <Row className="mt-5">
                        <Col sm={12} md={6}>
                          <Card>
                            <Card.Body>
                              <BrutoLiquidoParceiro producaoBruta={producaoBruta} producaoLiquida={producaoLiquida} />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col sm={12} md={6}>
                          {/* Comissão Total */}
                          <Card className="bg-success mt-4" style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faHandHoldingDollar} />
                                  </span>
                                  <span>Comissão Total:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>{comissaoData ? formatCurrency(comissaoData.Comissao_Recebida) : 'R$ 0,00'}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* A Receber */}
                          <Card className="bg-secondary mt-4" style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                  </span>
                                  <span>A Receber:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>{comissaoData ? formatCurrency(comissaoData.Comissao_A_Receber) : 'R$ 0,00'}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* Bônus */}
                          <Card className="bg-warning mt-4">
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                  </span>
                                  <span>Bônus:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>{totalBonus !== null ? formatCurrency(totalBonus) : 'R$ 0,00'}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          {/* Estornos */}
                          <Card className="bg-danger mt-4" style={{ color: 'white' }}>
                            <Card.Body>
                              <Row>
                                <Col className="d-flex justify-content-start">
                                  <span className="me-2">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                  </span>
                                  <span>Estornos:</span>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <span>{estornoData ? formatCurrency(estornoData.Comissao_Recebida) : 'R$ 0,00'}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>

                      </Row>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>

          {/* Produção - Convênios and Operações */}
          <Row className="mt-3">
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Convênios</Card.Header>
                    <Card.Body>
                      <ProducaoConvenioBar data={producaoPorConvenio} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Operações</Card.Header>
                    <Card.Body>
                      <ProducaoOperacao data={operacoesArray}/>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>

          {/* Evolução Semanal */}
          <Row className="mt-3">
            <Col xs={12} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Evolução por Semana</Card.Header>
                    <Card.Body>
                      <ProducaoTabela data={producaoSemanalArray} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>
        </Container>

        {/* Modals */}
        <ComparativoProducaoModal
          show={showModal}
          onHide={closeModal}
          dadosProducao={dadosProducao as ProducaoMes[]}
        />

      </motion.div>
    </>
  );
}
