import React, { useState, useEffect } from "react";
import { Form, Row, Col, Badge } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Parceiro {
  cod_produtor_venda: number;
  nom_produtor_venda: string;
}

interface FilterTag {
  key: string;
  label: string;
  value: string;
}

export default function FilterComercialSuperintendente({
  setIdParceiro,
  setNomeParceiro,
  setDataInicio,
  setDataFim,
  onBaseChange,
}: {
  setIdParceiro?: Function;
  setNomeParceiro?: Function;
  setDataInicio?: Function;
  setDataFim?: Function;
  onBaseChange: Function;
}) {
  const [parceiros, setParceiros] = useState<{ value: number; label: string }[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<number | null>(null);
  const [selectedBase, setSelectedBase] = useState<string | null>(null);
  const [typeProd, setTypeProd] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterTag[]>([]);

  const codSuperintendente = localStorage.getItem('codSuperintendente');

  useEffect(() => {
    const fetchParceiros = async () => {
      try {
        const baseAtual = localStorage.getItem("selectedBase");
        let url = `https://bi.gfttech.com.br/api/v2/superintendente/producao?codSuperintendente=${codSuperintendente}&base=${baseAtual}`;

        if (typeProd === "Bruto") {
          url += "&tipo_producao=Bruto";
        } else if (typeProd === "Repasse") {
          url += "&tipo_producao=Repasse";
        }

        const response = await axios.get(url);
        const data = response.data;

        const uniquePartners = new Set<number>();
        const options: { value: number; label: string }[] = [];

        data.forEach((item: any) => {
          const { cod_produtor_venda, nom_produtor_venda } = item;
          if (cod_produtor_venda && !uniquePartners.has(cod_produtor_venda)) {
            uniquePartners.add(cod_produtor_venda);
            options.push({
              value: cod_produtor_venda,
              label: nom_produtor_venda,
            });
          }
        });

        setParceiros(options);
      } catch (error) {
        console.error("Erro ao buscar parceiros:", error);
      }
    };

    fetchParceiros();
  }, [selectedBase, selectedPartner, typeProd]);

  useEffect(() => {
    const updateFiltersFromLocalStorage = () => {
      const filters: FilterTag[] = [];
      const storedBase = localStorage.getItem("selectedBase");
      const storedPartner = localStorage.getItem("selectedPartner");
      const storedTypeProd = localStorage.getItem("typeProd");

      if (storedBase) {
        filters.push({ key: "Base", label: storedBase, value: storedBase });
      }
      if (storedPartner) {
        const parceiro = parceiros.find(p => p.value === parseInt(storedPartner));
        if (parceiro) {
          filters.push({
            key: "Parceiro",
            label: `${parceiro.value} - ${parceiro.label}`,
            value: parceiro.value.toString()
          });
        }
      }
      if (storedTypeProd) {
        filters.push({ key: "Tipo de Produção", label: storedTypeProd, value: storedTypeProd });
      }

      setSelectedFilters(filters);
      setSelectedBase(storedBase || null);
      setSelectedPartner(storedPartner ? parseInt(storedPartner) : null);
      setTypeProd(storedTypeProd || null);
    };

    updateFiltersFromLocalStorage();
  }, [parceiros]);

  const handlePartnerChange = (option: SingleValue<{ value: number; label: string }>) => {
    const newPartner = option ? option.value : null;
    setSelectedPartner(newPartner);
    localStorage.setItem("selectedPartner", newPartner ? newPartner.toString() : "");
    updateSelectedFilters("Parceiro", option ? option.label : "");
  };

  const handleBaseChange = (option: SingleValue<{ value: string; label: string }>) => {
    const newBase = option ? option.value : null;
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase || "");
    onBaseChange(newBase);
    updateSelectedFilters("Base", newBase || "");
  };

  const handleTypeProdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTypeProd = event.target.value;
    setTypeProd(newTypeProd);
    localStorage.setItem("typeProd", newTypeProd);
    updateSelectedFilters(
      "Tipo de Produção",
      newTypeProd === "Bruto" ? "Bruto" : "Repasse"
    );
  };

  const updateSelectedFilters = (key: string, label: string) => {
    const existingFilterIndex = selectedFilters.findIndex(
      (filter) => filter.key === key
    );
    const newFilters = [...selectedFilters];

    if (existingFilterIndex !== -1) {
      newFilters[existingFilterIndex] = { key, label, value: label };
    } else {
      newFilters.push({ key, label, value: label });
    }

    setSelectedFilters(newFilters);
  };

  const removeFilter = (key: string) => {
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.key !== key
    );
    setSelectedFilters(updatedFilters);

    if (key === "Base") {
      setSelectedBase(null);
      localStorage.removeItem("selectedBase");
    } else if (key === "Parceiro") {
      setSelectedPartner(null);
      localStorage.removeItem("selectedPartner");
    } else if (key === "Tipo de Produção") {
      setTypeProd(null);
      localStorage.removeItem("typeProd");
    }
  };

  const optionsBase: { value: string; label: string }[] = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  for (let year = 2024; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const base = `${year}${month.toString().padStart(2, "0")}`;
      optionsBase.push({ value: base, label: base });
    }
  }

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Col md={6} className="mt-3">
            <Select
              options={optionsBase}
              onChange={handleBaseChange}
              value={
                selectedBase ? { value: selectedBase, label: selectedBase } : null
              }
              placeholder="Base"
            />
          </Col>
          <Col md={6} className="mt-3">
            <Select
              options={parceiros}
              onChange={handlePartnerChange}
              value={
                selectedPartner !== null
                  ? { value: selectedPartner, label: parceiros.find(p => p.value === selectedPartner)?.label || '' }
                  : null
              }
              isClearable
              placeholder="Selecione um parceiro..."
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <div className="d-flex flex-wrap">
            {selectedFilters.map((filter, index) => (
              <div key={index} className="mt-2 mr-2">
                <Badge pill bg="primary">
                  {filter.key === "Base" && `${filter.label}`}
                  {filter.key === "Parceiro" && `${filter.label}`}
                  {filter.key === "Tipo de Produção" && `Tipo: ${filter.label}`}
                  <span
                    className="ml-2"
                    onClick={() => removeFilter(filter.key)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </Badge>
              </div>
            ))}
          </div>
        </Row>
      </Form>
    </>
  );
}
