import { Card } from "react-bootstrap";
import styles from '../CardsProjecao.module.scss';
import React from "react";

interface ProducaoRestanteAtingimentoProps{
  valorProdRestante: number;
}

const ProducaoRestanteAtingimento: React.FC<ProducaoRestanteAtingimentoProps> = ({valorProdRestante}) =>{
  if(!valorProdRestante) valorProdRestante = 0;
  return(
    <>
      <Card>
        <Card.Header>
          <h5 className={`${styles.titleCard} display-6`}>
            <b>Falta para Atingir Meta</b>
          </h5>
        </Card.Header>
        <Card.Body>
          <div className={styles.cardContent}>
          R$ {valorProdRestante.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default ProducaoRestanteAtingimento;