import React, { useState, useEffect } from "react";
import { Form, Row, Col, Badge } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Parceiro {
  cod_produtor_venda: number;
  nom_produtor_venda: string;
}

interface FilterTag {
  key: string;
  label: string;
}

export default function FilterComercial({
  setIdParceiro,
  setNomeParceiro,
  setDataInicio,
  setDataFim,
  onBaseChange,
}: {
  setIdParceiro?: Function;
  setNomeParceiro?: Function;
  setDataInicio?: Function;
  setDataFim?: Function;
  onBaseChange: Function;
}) {
  const [parceiros, setParceiros] = useState<{ value: number; label: string }[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<number | null>(
    localStorage.getItem("selectedPartner")
      ? parseInt(localStorage.getItem("selectedPartner")!)
      : null
  );
  const [selectedBase, setSelectedBase] = useState<string | null>(
    localStorage.getItem("selectedBase") || null
  );
  const [typeProd, setTypeProd] = useState<string | null>(localStorage.getItem("typeProd") || null);
  const [selectedFilters, setSelectedFilters] = useState<FilterTag[]>([]);

  const codSupervisor = localStorage.getItem('codSupervisor');

  useEffect(() => {
    const fetchParceiros = async () => {
      try {
        const baseAtual = localStorage.getItem("selectedBase");
        let url = `https://bi.gfttech.com.br/api/v1/gerente/producaoGerente?codSupervisor=${codSupervisor}&base=${baseAtual}`;

        if (typeProd === "Bruto") {
          url += "&tipo_producao=Bruto";
        } else if (typeProd === "Repasse") {
          url += "&tipo_producao=Repasse";
        }

        const response = await fetch(url);
        const data: Parceiro[] = await response.json();
        const uniquePartners = new Set<number>();
        const options: { value: number; label: string }[] = [];

        data.forEach((parceiro) => {
          if (!uniquePartners.has(parceiro.cod_produtor_venda)) {
            uniquePartners.add(parceiro.cod_produtor_venda);
            options.push({
              value: parceiro.cod_produtor_venda,
              label: `${parceiro.cod_produtor_venda} - ${parceiro.nom_produtor_venda}`,
            });
          }
        });

        setParceiros(options);
      } catch (error) {
        console.error("Erro ao buscar parceiros:", error);
      }
    };

    fetchParceiros();
  }, [selectedBase, selectedPartner, typeProd]);

  const handlePartnerChange = (option: SingleValue<{ value: number; label: string }>) => {
    const newPartner = option ? option.value : null;
    setSelectedPartner(newPartner);
    localStorage.setItem("selectedPartner", newPartner ? newPartner.toString() : "");
    const partnerLabel = option ? option.label : "";
    updateSelectedFilters("Parceiro", partnerLabel);
  };

  const handleBaseChange = (option: SingleValue<{ value: string; label: string }>) => {
    const newBase = option ? option.value : null;
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase || "");
    onBaseChange(newBase);
    updateSelectedFilters("Base", newBase || "");
  };

  const handleTypeProdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTypeProd = event.target.value;
    setTypeProd(newTypeProd);
    localStorage.setItem("typeProd", newTypeProd);
    updateSelectedFilters(
      "Tipo de Produção",
      newTypeProd === "Bruto" ? "Bruto" : "Repasse"
    );
  };

  const updateSelectedFilters = (key: string, label: string) => {
    const existingFilterIndex = selectedFilters.findIndex(
      (filter) => filter.key === key
    );
    const newFilters = [...selectedFilters];

    if (existingFilterIndex !== -1) {
      newFilters[existingFilterIndex] = { key, label };
    } else {
      newFilters.push({ key, label });
    }

    setSelectedFilters(newFilters);
  };

  const removeFilter = (key: string) => {
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.key !== key
    );
    setSelectedFilters(updatedFilters);

    if (key === "Base") {
      setSelectedBase(null);
      localStorage.removeItem("selectedBase");
    } else if (key === "Parceiro") {
      setSelectedPartner(null);
      localStorage.removeItem("selectedPartner");
    } else if (key === "Tipo de Produção") {
      setTypeProd(null);
      localStorage.removeItem("typeProd");
    }
  };

  const optionsBase: { value: string; label: string }[] = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  for (let year = 2024; year <= currentYear; year++) {
    const maxMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= maxMonth; month++) {
      const base = `${year}${month.toString().padStart(2, "0")}`;
      optionsBase.push({ value: base, label: base });
    }
  }

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Col md={6} className="mt-3">
            <Select
              options={optionsBase}
              onChange={handleBaseChange}
              value={
                selectedBase ? { value: selectedBase, label: selectedBase } : null
              }
              placeholder="Base"
            />
          </Col>
          <Col md={6} className="mt-3">
            <Select
              options={parceiros}
              onChange={handlePartnerChange}
              value={
                selectedPartner !== null
                  ? {
                      value: selectedPartner,
                      label: parceiros.find(p => p.value === selectedPartner)?.label || ''
                    }
                  : null
              }
              isClearable
              placeholder="Selecione um parceiro..."
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <div className="d-flex flex-wrap">
            {selectedFilters.map((filter, index) => (
              <div key={index} className="mt-2 mr-2">
                <Badge pill bg="primary">
                  {filter.key === "Base" && `Base selecionada: ${filter.label}`}
                  {filter.key === "Parceiro" && `Parceiro: ${filter.label}`}
                  {filter.key === "Tipo de Produção" && `Tipo: ${filter.label}`}
                  <span
                    className="ml-2"
                    onClick={() => removeFilter(filter.key)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </Badge>
              </div>
            ))}
          </div>
        </Row>
      </Form>
    </>
  );
}
