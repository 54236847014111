import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteRegional";
import { motion } from 'framer-motion';
import logoVisionBi from '../../assets/images/logo-vision-bi.png'
import styles from './Styling.module.scss'
import FilterComercialRegional from "../../components/FilterComercialRegional";
import DataAgentesProdRegional from "../../components/Graficos/DataAgentesProdRegional";
import RankingAgentesRegional from "../../components/Graficos/RankingAgentesRegional";
import RankingSubsRegional from "../../components/Graficos/RankingSubsRegional";

export default function AgentAnalyticsRegional() {
  const [base, setBase] = useState<string | null>(null);
  const [partner, setPartner] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  useEffect(() => {
    const storedPartner = localStorage.getItem('selectedPartner');
    setPartner(storedPartner);
  }, []);

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercialRegional onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <DataAgentesProdRegional />
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card>
                <Card.Header>Ranking - Top 10 Produção Agentes</Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <RankingAgentesRegional base={base} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <Card>
                <Card.Header>Ranking - Top 10 Produção Substabelecidos</Card.Header>
                <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <RankingSubsRegional base={base} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  )
}
