import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import NavBar from '../../components/NavBar/GerenteComercial';
import FilterComercial from '../../components/FilterComercial';
import { motion } from 'framer-motion';
import SituacaoDataTable from '../../components/Graficos/SituacaoDataTable';
import SituacaoValorProducaoBarChart from '../../components/Graficos/SituacaoValorProducaoBarChart';
import SituacaoPizza from '../../components/Graficos/SituacaoPizza';
import styles from './Styling.module.scss'
import SituacaoIndicesAnual from '../../components/Graficos/SituacaoIndicesAnual';
import RankingIndiceIntegradas from '../../components/Graficos/RankingIndiceIntegradas';
import RankingIndiceCanceladas from '../../components/Graficos/RankingIndiceCanceladas';
import logoVisionBi from '../../assets/images/logo-vision-bi.png'
import SituacaoValorProducaoBarChartGerente from '../../components/Graficos/SituacaoValorProducaoBarChartGerente';

const SituationAnalytics: React.FC = () => {
  const [base, setBase] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <Col md="4">
              <SituacaoDataTable /> 
            </Col>
            <Col md="4">
              <SituacaoValorProducaoBarChartGerente/>
            </Col>
            <Col>
              <SituacaoPizza/>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <RankingIndiceIntegradas/>
            </Col>
            <Col md="6">
              <RankingIndiceCanceladas/>
            </Col>
          </Row>
          <Row>
            <SituacaoIndicesAnual/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default SituationAnalytics;
