import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";
import { motion } from "framer-motion";
import axios from 'axios';
import SpinnerVision from "../../Spinner";

interface Indice {
  name: string;
  value: number;
}

const SituacaoPizzaRegional: React.FC = () => {
  const [data, setData] = useState<Indice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));
  const [selectedPartner, setSelectedPartner] = useState<string | null>(localStorage.getItem("selectedPartner"));
  const [codRegional, setCodRegional] = useState<string | null>(localStorage.getItem('codRegional'));
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const newBase = localStorage.getItem("selectedBase");
      const newCodRegional = localStorage.getItem('codRegional');
      const newSelectedPartner = localStorage.getItem('selectedPartner');

      if (newBase !== selectedBase) {
        setSelectedBase(newBase);
      }
      if (newCodRegional !== codRegional) {
        setCodRegional(newCodRegional);
      }
      if (newSelectedPartner !== selectedPartner) {
        setSelectedPartner(newSelectedPartner);
      }
    }, 1000);

    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [selectedBase, codRegional, selectedPartner]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (selectedBase && codRegional) {
          let url = `https://bi.gfttech.com.br/api/v2/regional/indiceDigitacao?codRegional=${codRegional}&base=${selectedBase}`;
          if (selectedPartner) {
            url += `&codProdutorVenda=${selectedPartner}`;
          }
          const response = await axios.get(url);
          const responseData = response.data;

          let totalIntegradas = 0;
          let totalCanceladas = 0;
          let totalAnalise = 0;
          
          responseData.forEach((item: any) => {
            totalIntegradas += item.indiceIntegradas;
            totalCanceladas += item.indiceCanceladas;
            totalAnalise += item.indiceAnalise;
          });

          const mappedData: Indice[] = [
            { name: "INTEGRADAS", value: totalIntegradas },
            { name: "CANCELADAS", value: totalCanceladas },
            { name: "ANALISE", value: totalAnalise }
          ];

          setData(mappedData);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Erro ao buscar dados:", error.message);
        } else {
          console.error("Erro desconhecido:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedBase, codRegional, selectedPartner, intervalId]);

  const colors = ["#062173", "#0F6DFC", "#6D8EF2"];

  const renderCustomLabel = (entry: any) => {
    const { value } = entry;
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    const percent = ((value / total) * 100).toFixed(2);
    return `${percent}%`;
  };

  const customTooltipFormatter = (value: number) => {
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    const percent = ((value / total) * 100).toFixed(2);
    return `${percent}%`;
  };

  return (
    <Container className="mt-5">
      <Row className="mt-5">
        <Col xs={12}>
          <Card>
            <Card.Header>Índices Gerais - Situação</Card.Header>
            <Card.Body style={{ paddingBottom: "30px" }}>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <SpinnerVision />
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div style={{ width: "100%", height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={400} height={300} margin={{ left: 50 }}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          label={renderCustomLabel}
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={customTooltipFormatter} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </motion.div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SituacaoPizzaRegional;