import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import ModalConvenioGerente from "../../ModalsComercial/ModalConvenioGerente";

interface ProducaoConvenioBarProps {
  data: { convenio: string; val_bruto: number }[]; // Dados para o gráfico
  dataModal?: {
    produtor: string;
    convenios: { convenio: string; val_bruto: number }[]; // Dados para o modal
  }[]; 
}

const ProducaoConvenioBar: React.FC<ProducaoConvenioBarProps> = ({ data, dataModal }) => {
  const [modalData, setModalData] = useState<
    { produtor: string; total: number }[] | null
  >(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Ordenar os dados
  const sortedData = data.sort((a, b) => b.val_bruto - a.val_bruto);

  // Definindo cores para as barras
  const colors = ["#062173", "#0F6DFC"];

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace("R$", "")
      .trim();
  };

  const handleBarClick = (convenio: string) => {
    if (dataModal) {
      // Encontrar o produtor correto baseado no convenio
      const produtorData = dataModal
        .filter((item) => item.convenios.some((conv) => conv.convenio === convenio))
        .map((item) => {
          // Filtra os convenios do produtor e agrupa a produção total
          const convenios = item.convenios.filter((conv) => conv.convenio === convenio);
          const totalProducao = convenios.reduce((acc, curr) => acc + curr.val_bruto, 0);

          return {
            produtor: item.produtor,
            total: totalProducao,
          };
        });

      if (produtorData.length > 0) {
        setModalTitle(convenio); // Definir o título do modal com o nome do convênio
        setModalData(produtorData);
        setIsModalOpen(true);
      }
    }
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={sortedData} layout="vertical" barSize={30}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="convenio" width={150} />
          <Tooltip formatter={(value: number) => formatCurrency(value)} />
          <Bar
            dataKey="val_bruto"
            fill="#032064"
            onClick={(e) => handleBarClick(e.payload.convenio)} // Passa o convênio ao clicar na barra
            style={{ cursor: "pointer" }}
          >
            {sortedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
            <LabelList
              dataKey="val_bruto"
              position="insideRight"
              fill="#fff"
              style={{ fontSize: "12px", fontWeight: "bold", pointerEvents: "none" }}
              formatter={(value: number) =>
                value > 1000 ? `R$ ${(value / 1000).toFixed(1)}K` : `R$ ${value}`
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {isModalOpen && modalData && (
        <ModalConvenioGerente 
          data={modalData} 
          modalTitle={modalTitle} 
          onClose={() => setIsModalOpen(false)} 
        />
      )}
    </>
  );
};

export default ProducaoConvenioBar;
