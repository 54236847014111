import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";

interface LiquidoRepasseProps {
  valorBruto: number;
  valorRepasse: number;
}

const LiquidoRepasse: React.FC<LiquidoRepasseProps> = ({ valorBruto, valorRepasse }) => {
  // Definindo os dados para o gráfico
  const data = [
    { name: "Bruto", value: valorBruto },
    { name: "Repasse", value: valorRepasse },
  ];

  // Definindo as cores para as barras
  const COLORS = ["#062173", "#0F6DFC"];

  // Função para formatar valores em reais sem o símbolo da moeda
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value).replace("R$", "").trim();
  };

  // Calcula o maior valor entre valorBruto e valorRepasse
  const maxValue = Math.max(valorBruto, valorRepasse);

  // Define o valor máximo do eixo Y com uma margem adicional
  const maxYAxisValue = Math.ceil(maxValue * 1.1);

  // Personaliza a legenda para incluir os nomes "Bruto" e "Repasse" com as respectivas cores
  const CustomLegend = () => (
    <div className="mt-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
        <div style={{ width: 10, height: 10, backgroundColor: COLORS[0], marginRight: 5 }} />
        <span>Bruto</span>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 10, height: 10, backgroundColor: COLORS[1], marginRight: 5 }} />
        <span>Repasse</span>
      </div>
    </div>
  );

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart width={400} height={300} data={data}>
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value) => formatCurrency(Number(value))} domain={[0, maxYAxisValue]} fontSize={10} />
        <Tooltip formatter={(value) => formatCurrency(Number(value))} />
        <Legend content={<CustomLegend />} />
        <Bar dataKey="value" legendType="circle">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LiquidoRepasse;
