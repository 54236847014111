import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import axios from "axios";

interface Agente {
    cod_produtor_venda: number;
    nom_produtor_venda: string;
    tipo_funcao: string;
    val_bruto: number;
}

interface RankingAgentesProps {
    base: string | null;
}

const RankingAgentesRegional: React.FC<RankingAgentesProps> = ({ base }) => {
    const [agentData, setAgentData] = useState<Agente[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [codRegional, setCodRegional] = useState<string | null>(localStorage.getItem('codRegional'));

    // Função para atualizar estados baseado no localStorage
    const updateStatesFromLocalStorage = () => {
        const storedBase = localStorage.getItem('selectedBase');
        const storedCodRegional = localStorage.getItem('codRegional');

        if (storedBase !== baseState) {
            setBaseState(storedBase);
        }
        if (storedCodRegional !== codRegional) {
            setCodRegional(storedCodRegional);
        }
    };

    useEffect(() => {
        setBaseState(base);
    }, [base]);

    useEffect(() => {
        const fetchData = async () => {
            if (codRegional && baseState) {
                try {
                    const response = await axios.get(`https://bi.gfttech.com.br/api/v2/regional/producao`, {
                        params: {
                            codRegional,
                            base: baseState
                        }
                    });
                    const data: Agente[] = response.data;

                    // Filtrando apenas os agentes
                    const agentes = data.filter(agent => agent.tipo_funcao === 'Agente');

                    // Criando um mapa para agrupar e somar a produção de cada agente
                    const producaoPorAgente = new Map<number, number>();
                    agentes.forEach(agent => {
                        const totalProducao = producaoPorAgente.get(agent.cod_produtor_venda) || 0;
                        producaoPorAgente.set(agent.cod_produtor_venda, totalProducao + agent.val_bruto);
                    });

                    // Ordenando os agentes por produção total em ordem decrescente
                    const sortedAgentes = Array.from(producaoPorAgente.entries())
                        .sort(([, producaoA], [, producaoB]) => producaoB - producaoA)
                        .slice(0, 10); // Obtendo os top 10 agentes

                    // Mapeando os dados dos top 10 agentes
                    const top10Agentes = sortedAgentes.map(([cod_produtor_venda, producaoTotal]) => {
                        const agente = agentes.find(agent => agent.cod_produtor_venda === cod_produtor_venda);
                        return {
                            cod_produtor_venda,
                            nom_produtor_venda: agente?.nom_produtor_venda 
                                ? agente.nom_produtor_venda.length > 20 
                                    ? `${agente.nom_produtor_venda.slice(0, 20)}...`
                                    : agente.nom_produtor_venda 
                                : "",
                            tipo_funcao: 'Agente',
                            val_bruto: producaoTotal
                        };
                    });

                    setAgentData(top10Agentes);
                } catch (error) {
                    console.error("Erro ao buscar dados dos agentes:", error);
                }
            }
        };

        fetchData();
    }, [codRegional, baseState]);

    // Efeito para verificar mudanças no localStorage a cada segundo
    useEffect(() => {
        const interval = setInterval(() => {
            updateStatesFromLocalStorage();
        }, 1000); // Intervalo de verificação a cada segundo

        return () => clearInterval(interval);
    }, []); // Sem dependências, executa apenas uma vez ao montar o componente

    return (
        <div style={{ width: "100%", height: 600 }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={agentData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="nom_produtor_venda" width={200} />
                    <Tooltip formatter={(value: number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)} />
                    <Bar dataKey="val_bruto" fill="#062173" barSize={20} label={{ position: "right", formatter: (value: number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) }} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default RankingAgentesRegional;