import React from "react";
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";

interface DadosGraficoRoscaProps {
  meta: number | null;
  valorProduzido: number;
}

const DadosGraficoRosca: React.FC<DadosGraficoRoscaProps> = ({ meta, valorProduzido }) => {
  // Verifica se a meta é inválida (null ou 0)
  if (!meta || meta === 0) {
    return (
      <div style={{ textAlign: 'center', color: '#062173',fontWeight: 'bold', fontSize: '18px' }}>
        Meta ainda não cadastrada para esse mês
      </div>
    );
  }

  // Calculando a porcentagem que o valor produzido representa em relação à meta
  const percentual = (valorProduzido / meta) * 100;

  // Ajustando os dados para refletir a proporção correta
  const data = [
    { name: "Valor Produzido", value: valorProduzido },
    { name: "Faltante para Meta", value: Math.max(meta - valorProduzido, 0) },
  ];

  // Definindo as cores para as seções do gráfico
  const COLORS = ["#062173", "#0F6DFC"];

  // Função para formatar valores em reais
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Definindo a cor do texto com base na porcentagem de atingimento
  let textColor = "";
  if (percentual < 69) {
    textColor = "#808080";
  } else {
    textColor = "green";
  }

  console.log(meta)

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={90}
          paddingAngle={5}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => formatCurrency(Number(value))} />
        <Legend
          formatter={(value) => {
            const dataEntry = data.find(d => d.name === value);
            return `${value}: ${dataEntry ? formatCurrency(dataEntry.value) : ''}`;
          }}
        />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={21}
          fontWeight="bold"
          fill={textColor}
        >
          {percentual.toFixed(2)}%
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DadosGraficoRosca;
