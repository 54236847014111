import React, { PureComponent, ChangeEvent } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Card, Form, Spinner } from 'react-bootstrap';
import SpinnerVision from '../../Spinner';

interface IndiceData {
  mesBase: string;
  digitacaoTotal: number;
  quantidade: number;
  indice: number;
}

interface SituacaoIndicesAnualState {
  filtro: 'INDICE INTEGRADAS' | 'INDICE CANCELADAS';
  data: IndiceData[];
  isLoading: boolean;
}

const percentFormatter = (value: number) => {
  return `${value.toFixed(2)}%`;
};

export default class SituacaoIndicesAnual extends PureComponent<{}, SituacaoIndicesAnualState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      filtro: 'INDICE INTEGRADAS',
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const codSupervisor = localStorage.getItem('codSupervisor');

    try {
      const response = await fetch(`https://bi.gfttech.com.br/api/v1/parceiro/indices?codSupervisor=${codSupervisor}`);
      const responseData = await response.json();

      const aggregatedData: { [key: string]: { digitacaoTotal: number; quantidade: number; indice: number; count: number } } = {};

      responseData.forEach((item: any) => {
        if (!aggregatedData[item.base]) {
          aggregatedData[item.base] = { digitacaoTotal: 0, quantidade: 0, indice: 0, count: 0 };
        }
        aggregatedData[item.base].digitacaoTotal += item.quantDigitadas || 0;
        aggregatedData[item.base].quantidade += this.state.filtro === 'INDICE INTEGRADAS' ? item.quantIntegradas || 0 : item.quantCanceladas || 0;
        aggregatedData[item.base].indice += this.state.filtro === 'INDICE INTEGRADAS' ? item.indiceIntegradas || 0 : item.indiceCanceladas || 0;
        aggregatedData[item.base].count += 1;
      });

      const data: IndiceData[] = Object.keys(aggregatedData).map((key) => ({
        mesBase: key,
        digitacaoTotal: aggregatedData[key].digitacaoTotal,
        quantidade: aggregatedData[key].quantidade,
        indice: aggregatedData[key].indice / aggregatedData[key].count,
      }));

      this.setState({ data, isLoading: false });
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      this.setState({ isLoading: false });
    }
  };

  handleFiltroChange = (event: ChangeEvent<any>) => {
    const filtro = event.target.value as 'INDICE INTEGRADAS' | 'INDICE CANCELADAS';
    this.setState({ filtro, isLoading: true }, this.fetchData);
  };

  render() {
    const { filtro, data, isLoading } = this.state;

    return (
      <div className="mt-5">
        <Card>
          <Card.Header>
            Acompanhamento de Índices - Anual
          </Card.Header>
          <Card.Body>
            <Form.Group controlId="filtroSelect" className="mb-3">
              <Form.Label>Selecione o Índice</Form.Label>
              <Form.Control as="select" value={filtro} onChange={this.handleFiltroChange}>
                <option value="INDICE INTEGRADAS">Índice Integradas</option>
                <option value="INDICE CANCELADAS">Índice Canceladas</option>
              </Form.Control>
            </Form.Group>

            {isLoading ? (
              <div className="d-flex justify-content-center">
                <SpinnerVision/>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 60,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="mesBase" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" tickFormatter={percentFormatter} domain={[0, 100]} />
                  <Tooltip formatter={(value: number | string, name: string) => {
                    if (name === "Índice (%)") {
                      return [percentFormatter(value as number), name];
                    }
                    return [value as number, name];
                  }} />
                  <Legend />
                  <Bar yAxisId="left" dataKey="digitacaoTotal" name="Digitação Total" barSize={40} fill="#062173" />
                  <Bar yAxisId="left" dataKey="quantidade" name={filtro === 'INDICE INTEGRADAS' ? 'Quantidade Integradas' : 'Quantidade Canceladas'} barSize={40} fill="#0F6DFC" />
                  <Line yAxisId="right" type="monotone" dataKey="indice" name="Índice (%)" stroke="#FFA500" strokeWidth={2} strokeDasharray="0 0" />
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}
