import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import SpinnerVision from "../../Spinner";

interface Proposta {
    NOM_BANCO: string;
    val_bruto: number;
}

interface DataBancoProdParceiroProps {
    base: string | null;
}

const DataBancoProdParceiro: React.FC<DataBancoProdParceiroProps> = ({ base }) => {
    const [bankData, setBankData] = useState<{ NOM_BANCO: string, total: number }[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem('selectedPartner') || '');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const codIndicador = localStorage.getItem('codIndicador');
    const codProdutorVenda = localStorage.getItem('codProdutorVenda');

    useEffect(() => {
        setBaseState(base);
    }, [base]);

    useEffect(() => {
        const interval = setInterval(() => {
            const storedBase = localStorage.getItem('selectedBase');
            const storedSelectedPartner = localStorage.getItem('selectedPartner');

            if (storedBase !== baseState) {
                setBaseState(storedBase);
            }

            if (storedSelectedPartner !== selectedPartner) {
                setSelectedPartner(storedSelectedPartner || '');
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [baseState, selectedPartner]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (baseState && codIndicador) {
                try {
                    // Adicionando codProdutorVenda à URL se existir
                    let url = `https://bi.gfttech.com.br/api/v2/indicador/producao?base=${baseState}&codSupervisor=${codIndicador}`;
                    if (codProdutorVenda) {
                        url += `&codProdutorVenda=${codProdutorVenda}`;
                    }

                    const response = await axios.get(url);
                    const data: Proposta[] = response.data;

                    // Agrupar val_bruto por NOM_BANCO
                    const groupedData: { [key: string]: number } = data.reduce((acc, proposta) => {
                        acc[proposta.NOM_BANCO] = (acc[proposta.NOM_BANCO] || 0) + proposta.val_bruto;
                        return acc;
                    }, {} as { [key: string]: number });

                    // Convertendo o objeto para array e ordenando por total
                    const aggregatedData = Object.keys(groupedData).map(banco => ({
                        NOM_BANCO: banco,
                        total: groupedData[banco]
                    }));
                    const sortedData = aggregatedData.sort((a, b) => b.total - a.total);

                    setBankData(sortedData);
                } catch (error) {
                    console.error("Erro ao buscar dados das propostas:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [codIndicador, baseState, selectedPartner, codProdutorVenda]);

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: '28px' }}>Ranking Produção por Banco</h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankData.map((bank) => (
                                <tr key={bank.NOM_BANCO}>
                                    <td>{bank.NOM_BANCO}</td>
                                    <td>{bank.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoProdParceiro;