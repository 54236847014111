import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";
import { motion } from "framer-motion";
import SpinnerVision from "../../Spinner";

interface Indice {
  name: string;
  value: number;
}

const SituacaoPizza: React.FC = () => {
  const [data, setData] = useState<Indice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));
  const [selectedPartner, setSelectedPartner] = useState<string | null>(localStorage.getItem("selectedPartner"));
  const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codSupervisor'));
  const [intervalId, setIntervalId] = useState<any | null>(null);

  // Verifica e atualiza as variáveis a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      const newBase = localStorage.getItem("selectedBase");
      const newCodSupervisor = localStorage.getItem('codSupervisor');
      const newSelectedPartner = localStorage.getItem('selectedPartner');

      if (newBase !== selectedBase) {
        setSelectedBase(newBase);
      }
      if (newCodSupervisor !== codSupervisor) {
        setCodSupervisor(newCodSupervisor);
      }
      if (newSelectedPartner !== selectedPartner) {
        setSelectedPartner(newSelectedPartner);
      }
    }, 1000); // Verifica a cada 1 segundo

    setIntervalId(interval);
    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, [selectedBase, codSupervisor, selectedPartner]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (selectedBase && codSupervisor) {
          let url = `https://bi.gfttech.com.br/api/v1/parceiro/indices?base=${selectedBase}&codSupervisor=${codSupervisor}`;
          if (selectedPartner) {
            url += `&codProdutorVenda=${selectedPartner}`;
          }
          const response = await fetch(url);
          const responseData = await response.json();

          // Calcular a soma dos índices para todos os objetos retornados
          let totalIntegradas = 0;
          let totalCanceladas = 0;
          let totalAnalise = 0;
          
          responseData.forEach((item: any) => {
            totalIntegradas += item.indiceIntegradas;
            totalCanceladas += item.indiceCanceladas;
            totalAnalise += item.indiceAnalise;
          });

          // Mapear os dados recebidos para o formato esperado pelo gráfico
          const mappedData: Indice[] = [
            { name: "INTEGRADAS", value: totalIntegradas },
            { name: "CANCELADAS", value: totalCanceladas },
            { name: "ANALISE", value: totalAnalise }
          ];

          setData(mappedData);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedBase, codSupervisor, selectedPartner, intervalId]);

  const colors = ["#062173", "#0F6DFC", "#6D8EF2"];

  const renderCustomLabel = (entry: any) => {
    const { value } = entry;
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    const percent = ((value / total) * 100).toFixed(2);
    return `${percent}%`;
  };

  const customTooltipFormatter = (value: number) => {
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    const percent = ((value / total) * 100).toFixed(2);
    return `${percent}%`;
  };

  return (
    <Container className="mt-5">
      <Row className="mt-5">
        <Col xs={12}>
          <Card>
            <Card.Header>Índices Gerais - Situação</Card.Header>
            <Card.Body style={{ paddingBottom: "30px" }}>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <SpinnerVision/>
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div style={{ width: "100%", height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={400} height={300} margin={{ left: 50 }}>
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          label={renderCustomLabel}
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                          ))}
                        </Pie>
                        <Tooltip formatter={customTooltipFormatter} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </motion.div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SituacaoPizza;
