import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import SpinnerVision from "../../Spinner";

interface Proposta {
    cod_proposta_emprestimo: number;
    num_contrato: string;
    cod_produtor_venda: number;
    nom_produtor_venda: string;
    cod_supervisor: number;
    nom_supervisor: string;
    COD_GER_Superintendente: number;
    nom_ger_Superintendente: string;
    cod_superintendente: number;
    NOM_SUPERINTENDENTE: string;
    nom_fantasia: string;
    val_bruto: number;
    val_repasse: number;
    val_liquido: number;
    dat_confirmacao: string;
    dat_credito: string;
    dat_emprestimo: string;
    nom_banco: string;
    dsc_grupo: string;
    dsc_produto: string;
    cod_loja_digitacao: number | null;
    dsc_situacao_emprestimo: string;
    tipo_funcao: string;
    dsc_tipo_proposta_emprestimo: string;
    cod_produtor_venda_tabela_espelho: number | null;
    cod_produto: number;
    cod_tipo_funcao: number;
    dsc_tipo_funcao: string;
    num_proposta: string;
    COD_CLIENTE: string;
}

interface DataBancoProdProps {
    base: string | null;
}

const DataBancoProdSuperintendente: React.FC<DataBancoProdProps> = ({ base }) => {
    const [bankData, setBankData] = useState<{ nom_banco: string, totalAgente: number, totalSubstabelecido: number }[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem('selectedPartner') || '');
    const [intervalId, setIntervalId] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const codSuperintendente = localStorage.getItem('codSuperintendente');

    useEffect(() => {
        setBaseState(base);
    }, [base]);

    useEffect(() => {
        const interval = setInterval(() => {
            const storedBase = localStorage.getItem('selectedBase');
            const storedSelectedPartner = localStorage.getItem('selectedPartner');

            if (storedBase !== baseState) {
                setBaseState(storedBase);
            }

            if (storedSelectedPartner !== selectedPartner) {
                setSelectedPartner(storedSelectedPartner || '');
            }
        }, 1000);

        setIntervalId(interval);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [baseState, selectedPartner]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (codSuperintendente && baseState) {
                try {
                    let url = `https://bi.gfttech.com.br/api/v2/Superintendente/producao?codSuperintendente=${codSuperintendente}&base=${baseState}`;

                    if (selectedPartner) {
                        url += `&codProdutorVenda=${selectedPartner}`;
                    }

                    const { data } = await axios.get<Proposta[]>(url);

                    const groupedData: { [key: string]: { nom_banco: string, totalAgente: number, totalSubstabelecido: number } } = {};
                    data.forEach((proposta) => {
                        if (!(proposta.nom_banco in groupedData)) {
                            groupedData[proposta.nom_banco] = {
                                nom_banco: proposta.nom_banco,
                                totalAgente: 0,
                                totalSubstabelecido: 0
                            };
                        }
                        if (proposta.tipo_funcao === 'Agente') {
                            groupedData[proposta.nom_banco].totalAgente += proposta.val_bruto;
                        } else if (proposta.tipo_funcao === 'Subestabelecido') {
                            groupedData[proposta.nom_banco].totalSubstabelecido += proposta.val_bruto;
                        }
                    });

                    const aggregatedData = Object.values(groupedData);
                    const sortedData = aggregatedData.sort((a, b) => (b.totalAgente + b.totalSubstabelecido) - (a.totalAgente + a.totalSubstabelecido));

                    setBankData(sortedData);
                } catch (error) {
                    console.error("Erro ao buscar dados das propostas:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [codSuperintendente, baseState, selectedPartner, intervalId]);

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: '28px' }}>Ranking Produção por Banco</h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Agente</th>
                                <th>Substabelecido</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankData.map((bank) => {
                                const total = bank.totalAgente + bank.totalSubstabelecido;
                                return (
                                    <tr key={bank.nom_banco}>
                                        <td>{bank.nom_banco}</td>
                                        <td>{bank.totalAgente !== 0 ? bank.totalAgente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                                        <td>{bank.totalSubstabelecido !== 0 ? bank.totalSubstabelecido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                                        <td>{total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoProdSuperintendente;
