import React, { useState } from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import NavBar from '../../components/NavBar/Superintendente';
import { motion } from 'framer-motion';
import styles from './Styling.module.scss'
import logoVisionBi from '../../assets/images/logo-vision-bi.png'
import FilterComercialSuperintendente from '../../components/FilterComercialSuperintendente';
import SituacaoPizzaSuperintendente from '../../components/Graficos/SituacaoPizzaSuperintendente';
import RankingIndiceIntegradasSuperintendente from '../../components/Graficos/RankingIndiceIntegradasSuperintendente';
import RankingIndiceCanceladasSuperintendente from '../../components/Graficos/RankingIndiceCanceladasSuperintedente';
import SituacaoIndicesAnualSuperintendente from '../../components/Graficos/SituacaoIndiceAnualSuperintendente';
import SituacaoDataTableSuperintendente from '../../components/Graficos/SituacaoDataTableSuperintendente';
import SituacaoValorProducaoBarChartSuperintendente from '../../components/Graficos/SituacaoValorProducaoBarChartSuperintendente';

const SituationAnalyticsSuperintendente: React.FC = () => {
  const [base, setBase] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercialSuperintendente onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <Col md="4">
             <SituacaoDataTableSuperintendente />
            </Col>
            <Col md="4">
              <SituacaoValorProducaoBarChartSuperintendente/>
            </Col>
            <Col>
              <SituacaoPizzaSuperintendente/>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <RankingIndiceIntegradasSuperintendente/>
            </Col>
            <Col md="6">
              <RankingIndiceCanceladasSuperintendente/>
            </Col>
          </Row>
          <Row>
            <SituacaoIndicesAnualSuperintendente/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default SituationAnalyticsSuperintendente;
