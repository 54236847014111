import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { format } from 'date-fns';
import axios from 'axios';
import SpinnerVision from "../../Spinner";

interface Proposta {
    nom_banco: string;
    dsc_grupo: string;
    val_bruto: number;
    cod_produtor_venda: number;
}

interface DataBancoConvenioProps {
    base: string | null;
}

const DataBancoConvenioSuperintendente: React.FC<DataBancoConvenioProps> = ({ base }) => {
    const [bankConvenioData, setBankConvenioData] = useState<{ banco: string, convenio: string, total: number }[]>([]);
    const [codSuperintendente, setCodSuperintendente] = useState<string | null>(localStorage.getItem('codSuperintendente'));
    const [baseSelecionada, setBaseSelecionada] = useState<string>(base || format(new Date(), 'yyyyMM'));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem('selectedPartner') || '');
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const interval = setInterval(() => {
            const storedBase = localStorage.getItem('selectedBase');
            const storedCodSuperintendente = localStorage.getItem('codSuperintendente');
            const storedSelectedPartner = localStorage.getItem('selectedPartner');

            if (storedBase !== baseSelecionada) {
                setBaseSelecionada(storedBase || format(new Date(), 'yyyyMM'));
            }

            if (storedCodSuperintendente !== codSuperintendente) {
                setCodSuperintendente(storedCodSuperintendente);
            }

            if (storedSelectedPartner !== selectedPartner) {
                setSelectedPartner(storedSelectedPartner || '');
            }
        }, 1000); // Verifica a cada segundo

        setIntervalId(interval);

        return () => clearInterval(interval);
    }, [baseSelecionada, codSuperintendente, selectedPartner]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let url = `https://bi.gfttech.com.br/api/v2/Superintendente/producao?codSuperintendente=${codSuperintendente}&base=${baseSelecionada}`;

                if (selectedPartner) {
                    url += `&codProdutorVenda=${selectedPartner}`;
                }

                const { data } = await axios.get<Proposta[]>(url);

                const groupedData: { [key: string]: { banco: string, convenio: string, total: number } } = {};
                data.forEach((proposta) => {
                    const key = `${proposta.nom_banco} - ${proposta.dsc_grupo}`;
                    if (!groupedData[key]) {
                        groupedData[key] = {
                            banco: proposta.nom_banco,
                            convenio: proposta.dsc_grupo,
                            total: 0
                        };
                    }
                    groupedData[key].total += proposta.val_bruto;
                });

                const aggregatedData = Object.values(groupedData);
                const sortedData = aggregatedData.sort((a, b) => b.total - a.total);

                setBankConvenioData(sortedData);
            } catch (error) {
                // Verificação de tipo para lidar com o erro
                if (axios.isAxiosError(error)) {
                    console.error("Erro ao buscar dados das propostas:", error.message);
                } else {
                    console.error("Erro desconhecido:", error);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [baseSelecionada, codSuperintendente, selectedPartner, intervalId]);

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: '28px' }}>
                Ranking Banco/Convênio {selectedPartner ? `(Parceiro ${selectedPartner})` : ''}
            </h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Convenio</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankConvenioData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.banco}</td>
                                    <td>{item.convenio}</td>
                                    <td>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoConvenioSuperintendente;
