import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Row, Col } from "react-bootstrap";

interface BrutoLiquidoParceiroProps {
  producaoBruta: number;
  producaoLiquida: number;
}

const BrutoLiquidoParceiro: React.FC<BrutoLiquidoParceiroProps> = ({ producaoBruta, producaoLiquida }) => {
  const data = [
    { name: "Bruta", value: producaoBruta, color: "#062173" },
    { name: "Líquida", value: producaoLiquida, color: "#D9D9D9" }
  ];

  return (
    <div>
      <ResponsiveContainer width="100%" height={270}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            startAngle={180}
            endAngle={0}
            cx="50%"
            cy="50%"
            innerRadius={90}
            outerRadius={120}
            paddingAngle={3}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `R$ ${value.toLocaleString()}`} />
          <text
            x="50%"
            y="65%"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={24}
            fontWeight="bold"
            fill="#062173"
          >
            Bruto X Líquido
          </text>
        </PieChart>
      </ResponsiveContainer>

      {/* Legend below the chart */}
      <Row className="mt-4 text-center">
        {data.map((entry, index) => (
          <Col key={index} xs={6}>
            <div className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  backgroundColor: entry.color,
                  width: 20,
                  height: 20,
                  marginRight: 10
                }}
              />
              <span>{entry.name}: R$ {entry.value.toLocaleString()}</span>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BrutoLiquidoParceiro;
