import React, { useState, useEffect } from "react";
import { Table, Accordion } from "react-bootstrap";
import axios from "axios";
import SpinnerVision from "../../Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Proposta {
    nom_banco: string;
    val_bruto: number;
    tipo_funcao: string;
    nom_produtor_venda: string;
}

interface DataBancoProdProps {
    base: string | null;
}

const DataBancoProdRegional: React.FC<DataBancoProdProps> = ({ base }) => {
    const [bankData, setBankData] = useState<{ nom_banco: string, totalAgente: number, totalSubstabelecido: number, parceiros: { nome: string, total: number }[] }[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem('selectedPartner') || '');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [expandedBanks, setExpandedBanks] = useState<Set<string>>(new Set()); // Controle de quais bancos estão expandidos
    const codRegional = localStorage.getItem('codRegional');

    useEffect(() => {
        setBaseState(base);
    }, [base]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (codRegional && baseState) {
                try {
                    let url = `https://bi.gfttech.com.br/api/v2/regional/producao?codRegional=${codRegional}&base=${baseState}`;
                    if (selectedPartner) {
                        url += `&codProdutorVenda=${selectedPartner}`;
                    }

                    const { data } = await axios.get<Proposta[]>(url);

                    const groupedData: { [key: string]: { nom_banco: string, totalAgente: number, totalSubstabelecido: number, parceiros: { nome: string, total: number }[] } } = {};

                    data.forEach((proposta) => {
                        // Agrupando por banco
                        if (!(proposta.nom_banco in groupedData)) {
                            groupedData[proposta.nom_banco] = {
                                nom_banco: proposta.nom_banco,
                                totalAgente: 0,
                                totalSubstabelecido: 0,
                                parceiros: []
                            };
                        }

                        // Calculando o total por tipo de função
                        if (proposta.tipo_funcao === 'Agente') {
                            groupedData[proposta.nom_banco].totalAgente += proposta.val_bruto;
                        } else if (proposta.tipo_funcao === 'Subestabelecido') {
                            groupedData[proposta.nom_banco].totalSubstabelecido += proposta.val_bruto;
                        }

                        // Agrupando e somando por parceiro
                        const existingPartner = groupedData[proposta.nom_banco].parceiros.find((parceiro) => parceiro.nome === proposta.nom_produtor_venda);
                        if (existingPartner) {
                            existingPartner.total += proposta.val_bruto;
                        } else {
                            groupedData[proposta.nom_banco].parceiros.push({
                                nome: proposta.nom_produtor_venda,
                                total: proposta.val_bruto
                            });
                        }
                    });

                    // Ordenar os dados de banco e parceiros
                    const aggregatedData = Object.values(groupedData)
                        .map(bank => {
                            const sortedPartners = bank.parceiros.sort((a, b) => b.total - a.total); // Ordenando parceiros
                            return { ...bank, parceiros: sortedPartners };
                        })
                        .sort((a, b) => (b.totalAgente + b.totalSubstabelecido) - (a.totalAgente + a.totalSubstabelecido)); // Ordenar bancos por total

                    setBankData(aggregatedData);
                } catch (error) {
                    console.error("Erro ao buscar dados das propostas:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [codRegional, baseState, selectedPartner]);

    const handleBankClick = (bankName: string) => {
        setExpandedBanks((prev) => {
            const newExpandedBanks = new Set(prev);
            if (newExpandedBanks.has(bankName)) {
                newExpandedBanks.delete(bankName);
            } else {
                newExpandedBanks.add(bankName);
            }
            return newExpandedBanks;
        });
    };

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: '28px' }}>Ranking Produção por Banco</h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Agente</th>
                                <th>Substabelecido</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankData.map((bank) => {
                                const total = bank.totalAgente + bank.totalSubstabelecido;
                                return (
                                    <React.Fragment key={bank.nom_banco}>
                                        <tr onClick={() => handleBankClick(bank.nom_banco)}>
                                            <td>
                                                <span style={{ marginRight: '10px' }}>
                                                    {expandedBanks.has(bank.nom_banco) ? (
                                                        <FontAwesomeIcon icon={faChevronUp} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faChevronDown} />
                                                    )}
                                                </span>
                                                {bank.nom_banco}
                                            </td>
                                            <td>{bank.totalAgente !== 0 ? bank.totalAgente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                                            <td>{bank.totalSubstabelecido !== 0 ? bank.totalSubstabelecido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                                            <td>{total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                        {expandedBanks.has(bank.nom_banco) && (
                                            <tr>
                                                <td colSpan={4}>
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Parceiro</th>
                                                                <th>Total Produção</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bank.parceiros.map((parceiro) => (
                                                                <tr key={parceiro.nome}>
                                                                    <td>{parceiro.nome}</td>
                                                                    <td>{parceiro.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoProdRegional;
