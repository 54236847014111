import React from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faMinus } from "@fortawesome/free-solid-svg-icons";

interface ProducaoTabelaProps {
  data: { weekLabel: string; val_bruto: number; growth: string }[];
}

const ProducaoTabela: React.FC<ProducaoTabelaProps> = ({ data }) => {
  // Função para formatar valores em R$ 0,00
  const formatCurrency = (value: number) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Semana</th>
          <th>Produção (R$)</th>
          <th>Crescimento (%)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((weekData, index) => (
          <tr key={index}>
            <td>{weekData.weekLabel}</td>
            <td>{formatCurrency(weekData.val_bruto)}</td>
            <td>
              {parseFloat(weekData.growth) > 0 ? (
                <>
                  <FontAwesomeIcon icon={faArrowUp} color="green" /> {weekData.growth}%
                </>
              ) : parseFloat(weekData.growth) < 0 ? (
                <>
                  <FontAwesomeIcon icon={faArrowDown} color="red" /> {weekData.growth}%
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faMinus} color="gray" /> 0%
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProducaoTabela;
