import { Card } from "react-bootstrap";
import styles from '../CardsProjecao.module.scss';
import React from "react";

interface DeficitProjecaoProps{
  valorDeficit : number;
}

const DeficitProjecao: React.FC<DeficitProjecaoProps> = ({valorDeficit}) =>{
  if(!valorDeficit) valorDeficit = 0;
  return(
    <>
      <Card>
        <Card.Header>
          <h5 className={`${styles.titleCard} display-6`}>
            <b>Déficit Projeção</b>
          </h5>
        </Card.Header>
        <Card.Body>
          <div className={styles.cardContent}>
          R$ {valorDeficit.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default DeficitProjecao;