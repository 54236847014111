import React from "react";
import RouteComponent from "./route";

const App: React.FC<{ perfil?: string }> = ({ perfil }) => {
  return (
    <div className="App">
      <RouteComponent perfil={perfil} />
    </div>
  );
};

export default App;
