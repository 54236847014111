import React from 'react';
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import styles from './ModalOperacaoGerente.module.scss';

interface ModalOperacaoGerenteProps {
  data: { produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[];
  onClose: () => void;
}

const ModalOperacaoGerente: React.FC<ModalOperacaoGerenteProps> = ({ data, onClose }) => {
  // Obtém o nome da operação (assumindo que todas as operações têm o mesmo nome)
  const operacaoTitulo = data[0]?.operacoes[0]?.operacao || 'Operação';

  // Calcula o total de cada produtor
  const calculateTotal = (produtorData: { operacoes: { val_bruto: number }[] }) => {
    return produtorData.operacoes.reduce((subtotal, operacao) => subtotal + operacao.val_bruto, 0);
  };

  // Calcula o total geral de todos os produtores
  const calculateTotalGeral = () => {
    return data.reduce((total, produtorData) => total + calculateTotal(produtorData), 0);
  };

  // Filtra e ordena os dados: apenas produtores com valor maior que zero, ordenados do maior para o menor
  const sortedData = data
    .filter((produtorData) => calculateTotal(produtorData) > 0)  // Exclui valores zero
    .sort((a, b) => calculateTotal(b) - calculateTotal(a));  // Ordena do maior para o menor

  return (
    <Modal style={{ marginTop: 60 }} show={true} onHide={onClose} size="lg" className={styles.font}>
      <Modal.Header closeButton>
        <Modal.Title>Operação: {operacaoTitulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Exibindo o total geral acima da tabela */}
        <Alert variant="secondary">
          <h6>
            Produção Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(calculateTotalGeral())}
          </h6>
        </Alert>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Parceiro</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((produtorData, index) => (
              <tr key={index}>
                <td>{produtorData.produtor}</td>
                <td>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(calculateTotal(produtorData))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOperacaoGerente;
