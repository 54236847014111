import { Card } from "react-bootstrap";
import styles from '../CardsProjecao.module.scss';

interface MediaProducaoDiariaProps {
  valorMediaProdDiaria: number;
}

const MediaProducaoDiaria: React.FC<MediaProducaoDiariaProps> = ({ valorMediaProdDiaria }) => {
  if(!valorMediaProdDiaria) valorMediaProdDiaria = 0;
  return(
    <>
      <Card>
        <Card.Header>
          <h5 className={`${styles.titleCard} display-6`}>
            <b>Média Produção Diária</b>
          </h5>
        </Card.Header>
        <Card.Body>
          <div className={styles.cardContent}>
          R$ {valorMediaProdDiaria.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default MediaProducaoDiaria;