import React from "react";
import { Modal, Button, Table, Alert } from "react-bootstrap";

interface ModalConvenioGerenteProps {
  data: { produtor: string; total: number }[]; // Dados dos produtores e total de produção
  modalTitle: string; // Título do modal, que é o nome do convênio
  onClose: () => void;
}

const ModalConvenioGerente: React.FC<ModalConvenioGerenteProps> = ({
  data,
  modalTitle,
  onClose,
}) => {
  // Calcula o total geral de todos os produtores
  const calculateTotalGeral = () => {
    return data.reduce((total, produtorData) => total + produtorData.total, 0);
  };

  // Filtra e ordena os dados: apenas produtores com valor maior que zero, ordenados do maior para o menor
  const sortedData = data
    .filter((produtorData) => produtorData.total > 0)  // Exclui valores zero
    .sort((a, b) => b.total - a.total);  // Ordena do maior para o menor

  return (
    <Modal style={{ marginTop: 60 }} show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Convênio: {modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Exibindo o total geral acima da tabela */}
        <Alert variant="secondary">
          <h6>
            Produção Total:{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(calculateTotalGeral())}
          </h6>
        </Alert>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Produtor</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((produtorData, index) => (
              <tr key={index}>
                <td>{produtorData.produtor}</td>
                <td>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(produtorData.total)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConvenioGerente;
