import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';  
import { faBars, faBell, faUsers, faUserTie, faProjectDiagram, faCheckCircle, faBuildingColumns, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NavBar.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Modal } from 'react-bootstrap';
import logo from '../../../assets/images/3.png';
import ButtonPrimary from '../../ButtonPrimary';

interface NavBarProps {
    onToggleMenu?: (isMenuOpen: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ onToggleMenu }) => {
    
    const [menuOpen, closeMenu] = useState(localStorage.getItem('isMenuOpen') === 'true');

    useEffect(() => {
        localStorage.setItem('isMenuOpen', menuOpen.toString());
        if(onToggleMenu){
            onToggleMenu(menuOpen)
        }
    }, [menuOpen, onToggleMenu]);

    const toggleMenu = () => {
        closeMenu(!menuOpen);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 768) {
            closeMenu(false);
        }
    };

    const [showModal, setShowModal] = useState(false);
    const router = useNavigate();

    const handleAccount = () => {
        router('/MinhaConta'); 
    };

    const handleLogout = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const Logout = async () => {
        const token = localStorage.getItem('token');

        if(!token){
            console.error('nenhum token encontrado!');
            return;
        }

        try{
            const res = await fetch('https://webservices.gfttech.com.br/api/v1/sair', {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
                }
            });

            if(!res.ok){
                throw new Error('Logout falhou!');
            }

            localStorage.removeItem('token');
            localStorage.removeItem('codRegional');
            window.location.href = '/';
        } catch(error){
            console.error('Error no logout:', error);
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.sideBar} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.header}>
                    <img src={logo} alt="Logo" />
                    <FontAwesomeIcon 
                        icon={faBars} 
                        onClick={toggleMenu}
                        className={styles.bars}
                    />
                </div>
                <div className={styles.content}>
                    <NavLink 
                        to="/HomeRegional" 
                        className={styles.navItem}
                        onClick={closeMenuOnMobile}
                    >
                        <FontAwesomeIcon 
                            icon={faDollarSign} 
                            className={styles.navIcon}
                        />
                        <span className={styles.textItem}>Produção</span>
                    </NavLink>
                    <NavLink 
                        to="/AnaliticoAgenteRegional" 
                        className={styles.navItem}
                        onClick={closeMenuOnMobile}
                    >
                        <FontAwesomeIcon 
                            icon={faUsers} 
                            className={styles.navIcon}
                        />
                        <span className={styles.textItem}>Agentes</span>
                    </NavLink>
                    <NavLink 
                        to="/AnaliticoBancoRegional"
                        className={styles.navItem}
                        onClick={closeMenuOnMobile}
                    >
                        <FontAwesomeIcon 
                            icon={faBuildingColumns} 
                            className={styles.navIcon}
                        />
                        <span className={styles.textItem}>Bancos</span>
                    </NavLink>
                    <NavLink 
                        to="/AnaliticoSituacaoRegional"
                        className={styles.navItem}
                        onClick={closeMenuOnMobile}
                    >
                        <FontAwesomeIcon 
                            icon={faCheckCircle} 
                            className={styles.navIcon}
                        />
                        <span className={styles.textItem}>Situação</span>
                    </NavLink>
                    <NavLink 
                        to="/MetaProjecaoRegional"
                        className={styles.navItem}
                        onClick={closeMenuOnMobile}
                    >
                        <FontAwesomeIcon 
                            icon={faProjectDiagram} 
                            className={styles.navIcon}
                        />
                        <span className={styles.textItem}>Meta/Projeção</span>
                    </NavLink>
                  
                </div>
                
            </div>

            {!menuOpen && (
                <div className={styles.toggleMenu}>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMenu} className={styles.bars} />
                </div>
            )}

            <div className={`${styles.topBar} ${menuOpen ? styles.menuOpen : styles.menuClosed}`}>
                <div className={styles.topBarIconsContainer}>
             
                    <Dropdown>
                        <Dropdown.Toggle as="div" className={styles.dropdownIcon}>
                            <FontAwesomeIcon icon={faUserTie} className={styles.topBarIcons}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={styles.dropdown}>
                            
                            <Dropdown.Item 
                                onClick={handleLogout}
                                className={styles.dropdownItem}
                            >
                                Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>

                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title className={styles.logoutTitle}>Confirmar Saída?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button className='btn btn-secondary' onClick={handleCloseModal}>Cancelar</Button>
                                <ButtonPrimary content="Sair" onClick={Logout}/>
                            </Modal.Footer>
                        </Modal>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
