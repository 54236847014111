import { Card } from "react-bootstrap";
import styles from '../CardsProjecao.module.scss';
import React from "react";

interface ProjecaoMensalProps{
  valorProjecao: number;
}

const ProjecaoMensal: React.FC<ProjecaoMensalProps> = ({valorProjecao}) =>{
  if(!valorProjecao) valorProjecao = 0;
  return(
    <>
      <Card>
        <Card.Header>
          <h5 className={`${styles.titleCard} display-6`}>
            <b>Projeção Mensal</b>
          </h5>
        </Card.Header>
        <Card.Body>
          <div className={styles.cardContent}>
          R$ {valorProjecao.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjecaoMensal;