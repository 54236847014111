import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from 'framer-motion'
import { useState } from "react";
import styles from './Styling.module.scss'
import logoVisionBi from '../../../assets/images/logo-vision-bi.png'
import NavBarIndicador from "../../../components/NavBar/Indicador";
import FilterComercial from "../../../components/FilterComercial";
import DataBancoProd from "../../../components/Graficos/DataBancoProd";
import DataBancoConvenio from "../../../components/Graficos/DataBancoConvenio";
import ProducaoAnualBanco from "../../../components/Graficos/ProducaoAnualBanco";
import DataBancoProdParceiro from "../../../components/GraficosParceiros/DataBancoProdParceiro";
import DataBancoConvenioParceiro from "../../../components/GraficosParceiros/DataBancoConvenioParceiro";
import ProducaoAnualBancoParceiro from "../../../components/GraficosParceiros/ProducaoAnualBancoParceiro";

export default function BankAnalyticsIndicador() {
  const [base, setBase] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  return (
    <>
      <NavBarIndicador />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
             <FilterComercial onBaseChange={handleBaseChange} /> 
          </Row>
          <Row className="mt-5">
            <Col md={6} className="mt-5">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <DataBancoProdParceiro base={base} />
              </motion.div>
            </Col>
             <Col md={6} className="mt-5">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <DataBancoConvenioParceiro base={base} />
              </motion.div>
            </Col> 
          </Row>
           <Row className="mt-5 mb-5">
            <ProducaoAnualBancoParceiro/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
