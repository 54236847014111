import { useEffect, useState } from "react";
import { Container, Row, Tooltip as BootstrapTooltip } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteRegional";
import { motion } from 'framer-motion';
import styles from './Styling.module.scss'
import FilterComercialRegional from "../../components/FilterComercialRegional";
import ListManagers from "../../components/ListManagersRegional";

export default function MyManagers() {
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));

  const codRegional = localStorage.getItem('codRegional');

  const handleBaseChange = (newBase: string | null) => {
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase ?? '');
  };

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row className="mb-5">
            <FilterComercialRegional onBaseChange={handleBaseChange} />
          </Row>

          <ListManagers/>
        </Container>
      </motion.div>
    </>
  );
}
