import { useEffect, useState } from "react";
import { Col, Container, Row, OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteComercial";
import { motion } from 'framer-motion';
import FilterComercial from "../../components/FilterComercial";
import MetaDiariaRestante from "../../components/CardsProjecao/MetaDiariaRestante";
import MediaProducaoDiaria from "../../components/CardsProjecao/MediaProducaoDiaria";
import MetaDiariaMensal from "../../components/CardsProjecao/MetaDiariaMensal";
import ProducaoRestanteAtingimento from "../../components/CardsProjecao/ProducaoRestanteAtingimento";
import ProjecaoMensal from "../../components/CardsProjecao/ProjecaoMensal";
import styles from './Styling.module.scss'
import DeficitProjecao from "../../components/CardsProjecao/DeficitProjecao";
import ProductionVsMetaChart from "../../components/CardsProjecao/ProducaoVsMetaAno";

const descriptions = {
  MetaDiariaRestante: "Valor de produção diário necessário considerando produção atual.",
  MediaProducaoDiaria: "Média do que você produziu por dia nesse mês.",
  MetaDiariaMensal: "Valor de produção diário necessário considerando o valor da meta.",
  ProducaoRestanteAtingimento: "Produção restante para o atingimento da meta.",
  ProjecaoMensal: "Projeção desse mês considerando produção atual.",
  DeficitProjecao: "Valor faltante para atingimento caso a projeção se concretize."
};

export default function Projection() {
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));
  const [metaDiariaRestante, setMetaDiariaRestante] = useState(0);
  const [mediaProducaoDiaria, setMediaProducaoDiaria] = useState(0);
  const [metaDiariaMensal, setMetaDiariaMensal] = useState(0);
  const [producaoRestanteAtingimento, setProducaoRestanteAtingimento] = useState(0);
  const [projecaoMensal, setProjecaoMensal] = useState(0);
  const [deficitProjecao, setDeficitProjecao] = useState(0);

  const codSupervisor = localStorage.getItem('codSupervisor');

  const fetchData = async (endpoint: string) => {
    const response = await fetch(endpoint);
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    if (selectedBase) {
      fetchData(`https://bi.gfttech.com.br/api/v1/gerente/projecaoMensal?codSupervisor=${codSupervisor}&base=${selectedBase}`)
        .then(data => {
          setMetaDiariaRestante(data.metaDiariaRestante);
          setProducaoRestanteAtingimento(data.faltaParaMeta);
          setProjecaoMensal(data.projecao);
        });

      fetchData(`https://bi.gfttech.com.br/api/v1/gerente/mediaProducaoDias?codSupervisor=${codSupervisor}&base=${selectedBase}`)
        .then(data => setMediaProducaoDiaria(data.mediaProducaoAteDataAtual));

      fetchData(`https://bi.gfttech.com.br/api/v1/gerente/metaDiariaMensal?codSupervisor=${codSupervisor}&base=${selectedBase}`)
        .then(data => setMetaDiariaMensal(data.metaDiariaMensal));

      fetchData(`https://bi.gfttech.com.br/api/v1/gerente/deficitProjecao?codSupervisor=${codSupervisor}&base=${selectedBase}`)
        .then(data => setDeficitProjecao(data.defcity));
    }
  }, [selectedBase]);

  const handleBaseChange = (newBase: string | null) => {
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase ?? '');
  };

  const renderTooltip = (description: string) => (
    <BootstrapTooltip id="button-tooltip">
      {description}
    </BootstrapTooltip>
  );

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row className="mb-5">
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="left"
                overlay={renderTooltip(descriptions.MetaDiariaRestante)}
              >
                <div>
                  <MetaDiariaRestante valorMetaDiaria={metaDiariaRestante} />
                </div>
              </OverlayTrigger>
            </Col>
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip(descriptions.MediaProducaoDiaria)}
              >
                <div>
                  <MediaProducaoDiaria valorMediaProdDiaria={mediaProducaoDiaria} />
                </div>
              </OverlayTrigger>
            </Col>
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip(descriptions.MetaDiariaMensal)}
              >
                <div>
                  <MetaDiariaMensal valorMetaDiaria={metaDiariaMensal} />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip(descriptions.ProducaoRestanteAtingimento)}
              >
                <div>
                  <ProducaoRestanteAtingimento valorProdRestante={producaoRestanteAtingimento} />
                </div>
              </OverlayTrigger>
            </Col>
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip(descriptions.ProjecaoMensal)}
              >
                <div>
                  <ProjecaoMensal valorProjecao={projecaoMensal} />
                </div>
              </OverlayTrigger>
            </Col>
            <Col className="mt-3" md="4">
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip(descriptions.DeficitProjecao)}
              >
                <div>
                  <DeficitProjecao valorDeficit={deficitProjecao} />
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row className="mt-5">
            <ProductionVsMetaChart />
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
