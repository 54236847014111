import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { motion } from "framer-motion";
import { format, startOfYear, eachMonthOfInterval } from "date-fns";
import SpinnerVision from "../../../Spinner";

interface ProducaoBanco {
  mes: string;
  val_bruto: number;
}

interface Proposta {
  NOM_BANCO: string;
  dat_confirmacao: string;
  val_bruto: number;
  cod_produtor_venda: number; // Adicionado para filtrar por parceiro
}

export default function ProducaoAnualBancoParceiroSub() {
  const [bancoSelecionado, setBancoSelecionado] = useState<string>("");
  const [producaoData, setProducaoData] = useState<ProducaoBanco[]>([]);
  const [bancos, setBancos] = useState<string[]>([]);
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<string>("");
  const codParceiro = localStorage.getItem('codParceiro');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const anoAtual = new Date().getFullYear();
        const mesesDoAno = eachMonthOfInterval({
          start: startOfYear(new Date(anoAtual, 0)),
          end: new Date()
        }).map(mes => format(mes, 'yyyyMM'));

        const urls = mesesDoAno.map(mes => 
          `https://bi.gfttech.com.br/api/v2/agente/producao?codProdutorVenda=${codParceiro}&base=${mes}`
        );

        const responses = await Promise.all(urls.map(url => fetch(url)));
        const data: Proposta[][] = await Promise.all(responses.map(response => response.json()));

        // Combinar dados de todos os meses
        const combinedData = data.flat();

        // Extrair bancos únicos
        const bancosUnicos = Array.from(new Set(combinedData.map(proposta => proposta.NOM_BANCO)));
        setBancos(bancosUnicos);

        // Filtrar dados pelo banco selecionado
        let filteredData = combinedData;
        if (bancoSelecionado) {
          filteredData = combinedData.filter(proposta => proposta.NOM_BANCO === bancoSelecionado);
        }

        // Aplicar filtro pelo parceiro selecionado, se existir
        if (selectedPartner) {
          filteredData = filteredData.filter(proposta => proposta.cod_produtor_venda.toString() === selectedPartner);
        }

        // Agrupar por mês e somar os valores brutos
        const producaoMensal = filteredData.reduce((acc, proposta) => {
          const mes = format(new Date(proposta.dat_confirmacao), 'MMM');
          if (!acc[mes]) {
            acc[mes] = { mes, val_bruto: 0 };
          }
          acc[mes].val_bruto += proposta.val_bruto;
          return acc;
        }, {} as { [key: string]: ProducaoBanco });

        // Preencher meses faltantes com valor bruto zero
        const producaoCompleta = mesesDoAno.map(mes => {
          const mesAno = mes.slice(0, 4);  // ano em string
          const mesMes = mes.slice(4, 6);  // mês em string
          const mesFormatado = format(new Date(Number(mesAno), Number(mesMes) - 1), 'MMM');
          return producaoMensal[mesFormatado] || { mes: mesFormatado, val_bruto: 0 };
        });

        setProducaoData(producaoCompleta);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [codParceiro, bancoSelecionado, selectedPartner]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedSelectedPartner = localStorage.getItem('selectedPartner');

      if (storedSelectedPartner !== selectedPartner) {
        setSelectedPartner(storedSelectedPartner || '');
      }
    }, 1000); // Verifica a cada segundo

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedPartner]);

  const handleBancoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBancoSelecionado(event.target.value);
  };

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  const currencyFormatter = (value: number) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1.5 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <Container className="mt-5">
          <Row>
            <Col xs={12} md={4} className="mb-4">
              <Form.Group controlId="bancoSelect">
                <Form.Label>Selecione o Banco</Form.Label>
                <Form.Control
                  as="select"
                  value={bancoSelecionado}
                  onChange={(event) => handleBancoChange(event as any)}
                >
                  <option value="">Selecione...</option>
                  {bancos.map((banco) => (
                    <option key={banco} value={banco}>
                      {banco}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <SpinnerVision/>
                </div>
              ) : (
                animationComplete && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Card>
                      <Card.Header>Produção Anual {bancoSelecionado} {selectedPartner ? `(Parceiro ${selectedPartner})` : ''}</Card.Header>
                      <Card.Body>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={producaoData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mes" />
                            <Tooltip formatter={currencyFormatter} />
                            
                            <Bar dataKey="val_bruto" barSize={90} fill="#062173" />
                          </BarChart>
                        </ResponsiveContainer>
                      </Card.Body>
                    </Card>
                  </motion.div>
                )
              )}
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
