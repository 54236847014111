import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

interface Agente {
    cod_produtor_venda: number;
    nom_produtor_venda: string;
    tipo_funcao: string;
    val_bruto: number;
}

const DataAgentesProd: React.FC = () => {
    const [agentData, setAgentData] = useState<{ cod_produtor_venda: number, nom_produtor_venda: string, totalAgente: number, totalSubstabelecido: number }[]>([]);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem('selectedBase'));
    const [partnerState, setPartnerState] = useState<string | null>(localStorage.getItem('selectedPartner'));
    const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codSupervisor'));

    // Função para atualizar estados baseado no localStorage
    const updateStatesFromLocalStorage = () => {
        const storedBase = localStorage.getItem('selectedBase');
        const storedPartner = localStorage.getItem('selectedPartner');
        const storedCodSupervisor = localStorage.getItem('codSupervisor');
        
        if (storedBase !== baseState) {
            setBaseState(storedBase);
        }
        if (storedPartner !== partnerState) {
            setPartnerState(storedPartner);
        }
        if (storedCodSupervisor !== codSupervisor) {
            setCodSupervisor(storedCodSupervisor);
        }
    };

    // Efeito para buscar dados quando baseState, partnerState ou codSupervisor mudam
    useEffect(() => {
        const fetchData = async () => {
            if (codSupervisor && baseState) {
                try {
                    let url = `https://bi.gfttech.com.br/api/v1/gerente/producaoGerente?codSupervisor=${codSupervisor}&base=${baseState}`;
                    
                    if (partnerState) {
                        url += `&codProdutorVenda=${partnerState}`;
                    }
                    
                    const response = await fetch(url);
                    const data: Agente[] = await response.json();

                    // Agrupando os dados por código do produtor de venda
                    const groupedData: { [key: number]: { cod_produtor_venda: number, nom_produtor_venda: string, totalAgente: number, totalSubstabelecido: number } } = {};
                    data.forEach((agent) => {
                        if (!(agent.cod_produtor_venda in groupedData)) {
                            groupedData[agent.cod_produtor_venda] = {
                                cod_produtor_venda: agent.cod_produtor_venda,
                                nom_produtor_venda: agent.nom_produtor_venda,
                                totalAgente: 0,
                                totalSubstabelecido: 0
                            };
                        }
                        if (agent.tipo_funcao === 'Agente') {
                            groupedData[agent.cod_produtor_venda].totalAgente += agent.val_bruto;
                        } else if (agent.tipo_funcao === 'Subestabelecido') {
                            groupedData[agent.cod_produtor_venda].totalSubstabelecido += agent.val_bruto;
                        }
                    });

                    // Convertendo o objeto de mapa em um array de valores e ordenando
                    const aggregatedData = Object.values(groupedData);
                    const sortedData = aggregatedData.sort((a, b) => (b.totalAgente + b.totalSubstabelecido) - (a.totalAgente + a.totalSubstabelecido));

                    setAgentData(sortedData);
                } catch (error) {
                    console.error("Erro ao buscar dados dos agentes:", error);
                }
            }
        };

        fetchData();
    }, [codSupervisor, baseState, partnerState]);

    // Efeito para verificar mudanças no localStorage a cada segundo
    useEffect(() => {
        const interval = setInterval(() => {
            updateStatesFromLocalStorage();
        }, 1000); // Intervalo de verificação a cada segundo

        return () => clearInterval(interval);
    }, []); // Sem dependências, executa apenas uma vez ao montar o componente

    return (
        <div style={{ maxHeight: "580px", overflowY: "auto" }}>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Parceiro</th>
                        <th>Produção Agente</th>
                        <th>Produção Substabelecido</th>
                    </tr>
                </thead>
                <tbody>
                    {agentData.map((agent) => (
                        <tr key={agent.cod_produtor_venda}>
                            <td>{agent.nom_produtor_venda}</td>
                            <td>{agent.totalAgente !== 0 ? agent.totalAgente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                            <td>{agent.totalSubstabelecido !== 0 ? agent.totalSubstabelecido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DataAgentesProd;
