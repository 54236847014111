import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { motion } from "framer-motion";
import SpinnerVision from "../../Spinner";

interface PropostaData {
  codPropostaEmprestimo: number;
  codProdutorVenda: number;
  nomeBanco: string;
  valorBruto: number | null;
  valorRepasse: number | null;
  situacaoEmprestimo: string;
  codSupervisor: string;
  base: string;
}

interface SituacaoData {
  situacao: string;
  total: number;
}

const SituacaoValorProducaoBarChartGerente: React.FC = () => {
  const [situacaoData, setSituacaoData] = useState<SituacaoData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBase, setSelectedBase] = useState<string | null>(localStorage.getItem("selectedBase"));
  const [selectedPartner, setSelectedPartner] = useState<number | null>(localStorage.getItem("selectedPartner") ? parseInt(localStorage.getItem("selectedPartner")!) : null);
  const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codSupervisor'));
  const [intervalId, setIntervalId] = useState<any | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const newBase = localStorage.getItem("selectedBase");
      const newCodSupervisor = localStorage.getItem('codSupervisor');
      const newSelectedPartner = localStorage.getItem('selectedPartner');

      if (newBase !== selectedBase) {
        setSelectedBase(newBase);
      }
      if (newCodSupervisor !== codSupervisor) {
        setCodSupervisor(newCodSupervisor);
      }
      if (newSelectedPartner !== selectedPartner) {
        setSelectedPartner(newSelectedPartner ? parseInt(newSelectedPartner) : null);
      }
    }, 1000);

    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [selectedBase, codSupervisor, selectedPartner]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (selectedBase && codSupervisor) {
          let url = `https://bi.gfttech.com.br/api/v1/parceiro/dadosDigitacao?base=${selectedBase}&codSupervisor=${codSupervisor}`;
          if (selectedPartner !== null) {
            url += `&codProdutorVenda=${selectedPartner}`;
          }
          const response = await fetch(url);
          const data: PropostaData[] = await response.json();

          // Agrupar e contar os totais por situação
          const situacaoMap = new Map<string, number>();
          data.forEach((proposta) => {
            let situacao = proposta.situacaoEmprestimo;

            if (situacao === "CRC C/ PENDÊNCIA") {
              situacao = "CR CLIENTE";
            } 
            if (situacao === "AUTORREGULAÇÃO") situacao = "PAGO";
            if (situacao === null) situacao = "OUTROS";
            else if (situacao === "PAGO C/PENDÊNCIA") {
              situacao = "PAGO";
            }

            situacaoMap.set(situacao, (situacaoMap.get(situacao) || 0) + 1);
          });

          // Transformar o mapa em um array de objetos para o Recharts
          let situacaoArray: SituacaoData[] = [];
          situacaoMap.forEach((total, situacao) => {
            situacaoArray.push({ situacao, total });
          });

          // Ordenar o array do maior para o menor
          situacaoArray = situacaoArray.sort((a, b) => b.total - a.total);

          setSituacaoData(situacaoArray);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [selectedBase, codSupervisor, selectedPartner, intervalId]);

  return (
    <Container className="mt-5">
      <Row className="mt-5">
        <Col xs={12}>
          <Card>
            <Card.Header>Quantidade de Contratos por Situação</Card.Header>
            <Card.Body style={{ paddingBottom: "30px" }}>
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <SpinnerVision />
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div style={{ width: "100%", height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={situacaoData} layout="vertical">
                        <XAxis type="number" dataKey="total" hide />
                        <YAxis type="category" dataKey="situacao" width={120} fontSize={13} />
                        <Tooltip />
                        <Bar dataKey="total" fill="#0F6DFC" barSize={20} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </motion.div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SituacaoValorProducaoBarChartGerente;
