import { Card, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteComercial";
import FilterComercial from "../../components/FilterComercial";
import DataBancoProd from "../../components/Graficos/DataBancoProd";
import DataBancoConvenio from "../../components/Graficos/DataBancoConvenio";
import { motion } from 'framer-motion'
import { useState } from "react";
import ProducaoAnualBanco from "../../components/Graficos/ProducaoAnualBanco";
import styles from './Styling.module.scss'
import logoVisionBi from '../../assets/images/logo-vision-bi.png'

export default function BankAnalytics() {
  const [base, setBase] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>
          <Row className="mt-5">
            <Col md={6} className="mt-5">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <DataBancoProd base={base} />
              </motion.div>
            </Col>
            <Col md={6} className="mt-5">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              >
                <DataBancoConvenio base={base} />
              </motion.div>
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <ProducaoAnualBanco/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
