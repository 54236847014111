import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import ModalOperacaoGerente from "../../ModalsComercial/ModalOperacaoGerente";

interface ProducaoOperacaoProps {
  data: { operacao: string; val_bruto: number }[];
  dataModal?: { produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[];
}

const ProducaoOperacao: React.FC<ProducaoOperacaoProps> = ({ data, dataModal }) => {
  const [modalData, setModalData] = useState<{ produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Ordenar os dados
  const sortedData = data.sort((a, b) => b.val_bruto - a.val_bruto);

  // Definindo cores para as barras
  const colors = ["#062173", "#0F6DFC"];

  // Função para formatar valores em uma forma abreviada
  const formatAbbreviatedCurrency = (value: number) => {
    if (value >= 1000000) {
      return `R$ ${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `R$ ${(value / 1000).toFixed(1)}K`;
    }
    return `R$ ${value.toFixed(1)}`;
  };

  // Função para formatar valores como moeda completa
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const handleBarClick = (operacao: string) => {
    if (dataModal) {
      const filteredData = dataModal.map((produtor) => ({
        produtor: produtor.produtor,
        operacoes: produtor.operacoes.filter((op) => op.operacao === operacao),
      }));

      setModalData(filteredData);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={sortedData} layout="vertical" barSize={30}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tickFormatter={formatCurrency} />
          <YAxis type="category" dataKey="operacao" width={150} />
          <Tooltip formatter={(value: number) => formatCurrency(value)} />
          <Bar
            dataKey="val_bruto"
            fill="#032064"
            onClick={(e) => handleBarClick(e.payload.operacao)}
            style={{cursor: 'pointer'}}
          >
            {sortedData.map((entry, index) => {
              const barColor = colors[index % colors.length];
              const value = entry.val_bruto;

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={value > 0 ? barColor : "transparent"}
                />
              );
            })}
            <LabelList
              dataKey="val_bruto"
              position="insideRight"
              fill="#fff"
              style={{ fontSize: "12px", fontWeight: "bold", pointerEvents: "none" }}
              formatter={(value: number) =>
                value > 1000 ? formatAbbreviatedCurrency(value) : ""
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {isModalOpen && modalData && (
        <ModalOperacaoGerente data={modalData} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default ProducaoOperacao;