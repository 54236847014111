import React, { useState, useEffect } from "react";
import { Table, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SpinnerVision from "../../Spinner";

interface Proposta {
    nom_banco: string;
    dsc_grupo: string;
    val_bruto: number;
    cod_produtor_venda: number;
    nom_produtor_venda: string;
}

interface DataBancoConvenioProps {
    base: string | null;
}

const DataBancoConvenio: React.FC<DataBancoConvenioProps> = ({ base }) => {
    const [bankConvenioData, setBankConvenioData] = useState<{
        banco: string;
        convenio: string;
        total: number;
        parceiros: { nom_produtor_venda: string; total: number }[];
    }[]>([]);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem("codSupervisor"));
    const [baseSelecionada, setBaseSelecionada] = useState<string | null>(base || localStorage.getItem("selectedBase"));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem("selectedPartner") || "");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Monitor base changes every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedBase = localStorage.getItem("selectedBase");
            if (updatedBase !== baseSelecionada) {
                setBaseSelecionada(updatedBase);
            }
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [baseSelecionada]);

    const fetchData = async () => {
        if (!codSupervisor || !baseSelecionada) return;
        setIsLoading(true);
        try {
            let url = `https://bi.gfttech.com.br/api/v1/gerente/producaoGerente?codSupervisor=${codSupervisor}&base=${baseSelecionada}`;

            if (selectedPartner) {
                url += `&codProdutorVenda=${selectedPartner}`;
            }

            const response = await fetch(url);
            const data: Proposta[] = await response.json();

            const groupedData: {
                [key: string]: {
                    banco: string;
                    convenio: string;
                    total: number;
                    parceiros: { nom_produtor_venda: string; total: number }[];
                };
            } = {};
            data.forEach((proposta) => {
                const key = `${proposta.nom_banco} - ${proposta.dsc_grupo}`;
                if (!groupedData[key]) {
                    groupedData[key] = {
                        banco: proposta.nom_banco,
                        convenio: proposta.dsc_grupo,
                        total: 0,
                        parceiros: [],
                    };
                }
                groupedData[key].total += proposta.val_bruto;

                const parceiroIndex = groupedData[key].parceiros.findIndex(
                    (p) => p.nom_produtor_venda === proposta.nom_produtor_venda
                );
                if (parceiroIndex === -1) {
                    groupedData[key].parceiros.push({
                        nom_produtor_venda: proposta.nom_produtor_venda,
                        total: proposta.val_bruto,
                    });
                } else {
                    groupedData[key].parceiros[parceiroIndex].total += proposta.val_bruto;
                }
            });

            const aggregatedData = Object.values(groupedData);
            setBankConvenioData(aggregatedData.sort((a, b) => b.total - a.total));
        } catch (error) {
            console.error("Erro ao buscar dados das propostas:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [baseSelecionada, codSupervisor, selectedPartner]);

    const toggleRow = (rowKey: string) => {
        setExpandedRow(expandedRow === rowKey ? null : rowKey);
    };

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: "28px" }}>
                Ranking Banco/Convênio {selectedPartner ? `(Parceiro ${selectedPartner})` : ""}
            </h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Convenio</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankConvenioData.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        onClick={() => toggleRow(`${item.banco} - ${item.convenio}`)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <td>
                                            <FontAwesomeIcon
                                                icon={expandedRow === `${item.banco} - ${item.convenio}` ? faChevronUp : faChevronDown}
                                            />
                                            {item.banco}
                                        </td>
                                        <td>{item.convenio}</td>
                                        <td>{item.total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} style={{ padding: 0, border: 0 }}>
                                            <Collapse in={expandedRow === `${item.banco} - ${item.convenio}`}>
                                                <div>
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Parceiro</th>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.parceiros.map((parceiro, idx) => (
                                                                <tr key={idx}>
                                                                    <td>{parceiro.nom_produtor_venda}</td>
                                                                    <td>
                                                                        {parceiro.total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Collapse>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoConvenio;
