import { useState } from "react";
import { Col, Container, Form, ListGroup, Row, Tab, Nav } from "react-bootstrap";
import { motion } from 'framer-motion';
import logoVisionBi from '../../../assets/images/logo-vision-bi.png';
import styles from './Styling.module.scss';
import NavBarParceiro from "../../../components/NavBar/Parceiro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPalette, faUser } from "@fortawesome/free-solid-svg-icons";
import ButtonPrimary from "../../../components/ButtonPrimary";

export default function SettingsParceiro() {
  const [activeTab, setActiveTab] = useState("#conta");
  const [primaryColor, setPrimaryColor] = useState("#007bff"); // Default Bootstrap primary color
  const [secondaryColor, setSecondaryColor] = useState("#6c757d"); // Default Bootstrap secondary color
  const [logo, setLogo] = useState(logoVisionBi);

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  const handleLogoChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result as string || logoVisionBi);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <NavBarParceiro />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          {/* Alinhamento da Logo */}
          <Row className="d-flex justify-content-center mb-5">
            <img src={logo} style={{ maxWidth: '270px' }} alt="Vision BI Logo" />
          </Row>

          {/* Tabs com Navegação no Topo */}
          <Tab.Container id="tabs-example" activeKey={activeTab} onSelect={handleTabChange}>
            <Nav variant="tabs" className="justify-content-center mb-4">
              <Nav.Item>
                <Nav.Link eventKey="#conta" className={styles.navTab}>
                  <FontAwesomeIcon icon={faUser} className={styles.iconSpacing} /> Conta
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="#tema" className={styles.navTab}>
                  <FontAwesomeIcon icon={faPalette} className={styles.iconSpacing} /> Tema
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="#logo" className={styles.navTab}>
                  <FontAwesomeIcon icon={faEdit} className={styles.iconSpacing} /> Logo
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              {/* Tab "Conta" */}
              <Tab.Pane eventKey="#conta">
                <h5 className="text-center mb-4">Alterar Senha</h5>
                <Container className="mt-5">
                  <Row>
                    <Col md={12}>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          type="password"
                          id="floatingOldPassword"
                          placeholder="Senha Antiga"
                        />
                        <Form.Label htmlFor="floatingOldPassword">Senha Antiga</Form.Label>
                      </Form.Floating>
                    </Col>

                    <Col md={12}>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          type="password"
                          id="floatingNewPassword"
                          placeholder="Nova Senha"
                        />
                        <Form.Label htmlFor="floatingNewPassword">Nova Senha</Form.Label>
                      </Form.Floating>
                    </Col>

                    <Col md={12}>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          type="password"
                          id="floatingConfirmPassword"
                          placeholder="Confirmação de Senha"
                        />
                        <Form.Label htmlFor="floatingConfirmPassword">Confirmação de Senha</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <div className="mt-5 text-center">
                    <ButtonPrimary content="Salvar" onClick={() => console.log()} />
                  </div>
                </Container>
              </Tab.Pane>

              {/* Tab "Tema" */}
              <Tab.Pane eventKey="#tema">
                <h5 className="text-center mb-4">Alterar Tema</h5>
                <Container className="mt-5">
                  <Row className="mb-5">
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Cor Primária</Form.Label>
                        <Form.Control
                          type="color"
                          value={primaryColor}
                          onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>Cor Secundária</Form.Label>
                        <Form.Control
                          type="color"
                          value={secondaryColor}
                          onChange={(e) => setSecondaryColor(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="mt-5 text-center">
                    <ButtonPrimary content="Salvar Tema" onClick={() => console.log({ primaryColor, secondaryColor })} />
                  </div>
                </Container>
              </Tab.Pane>

              {/* Tab "Logo" */}
              <Tab.Pane eventKey="#logo">
                <h5 className="text-center mb-4">Alterar Logo</h5>
                <Container className="mt-5">
                  <Form.Group controlId="formFile" className="mb-4">
                    <Form.Label>Selecionar nova logo</Form.Label>
                    <Form.Control type="file" accept="image/*" onChange={handleLogoChange} />
                  </Form.Group>

                  <div className="mt-5 text-center">
                    <ButtonPrimary content="Salvar Logo" onClick={() => console.log(logo)} />
                  </div>
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </motion.div>
    </>
  );
}