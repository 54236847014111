import { faBriefcase, faChartLine, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import docao from "../../assets/images/docao.png";
import elesson from "../../assets/images/elesson.png";
import bruno from "../../assets/images/bruno.png";
import marcelo from "../../assets/images/marcelo.png";
import iarley from "../../assets/images/iarley.png";
import daniel from "../../assets/images/daniel.png";
import denis from "../../assets/images/denis.jpg";
import "./ListManagers.module.scss"; // Estilos adicionais para animações

interface Gerente {
    codigoGerente: number;
    nomeGerente: string;
    regiao: string;
    filial: string;
    mesBase: string;
    producaoDados: {
        valBruto: number;
        valRepasse: number;
        meta: number;
    };
    avatar: string;
    dadosAnalitico: {
        cadastrosRealizados: number;
        indConversao: number;
        contestacoes: number;
    };
}

const ListManagersRegional: React.FC = () => {
    const [agrupamento, setAgrupamento] = useState<"regiao" | "filial">("regiao");
    const [gerenteSelecionado, setGerenteSelecionado] = useState<Gerente | any>(null);

    const gerentes: Gerente[] = [
      {
          codigoGerente: 1,
          nomeGerente: "Bruno Duarte",
          regiao: "NORDESTE",
          filial: "MATRIZ",
          mesBase: "202411",
          producaoDados: { valBruto: 5000, valRepasse: 2500, meta: 5500 },
          avatar: bruno,
          dadosAnalitico: {
            cadastrosRealizados: 12,
            indConversao: 45.56,
            contestacoes: 0
          }
      },
      {
          codigoGerente: 2,
          nomeGerente: "Elesson Cruz",
          regiao: "NORTE",
          filial: "BELÉM",
          mesBase: "202411",
          producaoDados: { valBruto: 7000, valRepasse: 3000, meta: 6500 },
          avatar: elesson,
          dadosAnalitico: {
            cadastrosRealizados: 20,
            indConversao: 54.22,
            contestacoes: 3
          }
      },
      {
          codigoGerente: 3,
          nomeGerente: "Marcelo Tchelo",
          regiao: "NORTE",
          filial: "MANAUS",
          mesBase: "202411",
          producaoDados: { valBruto: 1200, valRepasse: 600, meta: 3500 },
          avatar: marcelo,
          dadosAnalitico: {
            cadastrosRealizados: 5,
            indConversao: 97.43,
            contestacoes: 0
          }
      },
      {
          codigoGerente: 4,
          nomeGerente: "Nykollas Régis",
          regiao: "NORDESTE",
          filial: "SALVADOR",
          mesBase: "202411",
          producaoDados: { valBruto: 6000, valRepasse: 2800, meta: 5500 },
          avatar: docao,
          dadosAnalitico: {
            cadastrosRealizados: 56,
            indConversao: 21.54,
            contestacoes: 2
          }
      },
      {
          codigoGerente: 5,
          nomeGerente: "Iarley Setinhas",
          regiao: "NORTE",
          filial: "PORTO VELHO",
          mesBase: "202411",
          producaoDados: { valBruto: 8000, valRepasse: 4000, meta: 7500 },
          avatar: iarley,
          dadosAnalitico: {
            cadastrosRealizados: 76,
            indConversao: 25.02,
            contestacoes: 1
          }
      },
      {
          codigoGerente: 6,
          nomeGerente: "Daniel Santos",
          regiao: "NORDESTE",
          filial: "RECIFE",
          mesBase: "202411",
          producaoDados: { valBruto: 8000, valRepasse: 4000, meta: 7500 },
          avatar: daniel,
          dadosAnalitico: {
            cadastrosRealizados: 2,
            indConversao: 84,
            contestacoes: 0
          }
      },
      {
        codigoGerente: 7,
        nomeGerente: "Denis Aguiar",
        regiao: "NORDESTE",
        filial: "MATRIZ",
        mesBase: "202411",
        producaoDados: { valBruto: 15000, valRepasse: 5500, meta: 20500 },
        avatar: denis,
        dadosAnalitico: {
          cadastrosRealizados: 12,
          indConversao: 45.56,
          contestacoes: 0
        }
    },
  ];

    const agruparPor = (chave: "regiao" | "filial") => {
        return gerentes.reduce((acumulador, gerente) => {
            const valor = gerente[chave];
            if (!acumulador[valor]) acumulador[valor] = [];
            acumulador[valor].push(gerente);
            return acumulador;
        }, {} as Record<string, Gerente[]>);
    };

    const dadosAgrupados = agruparPor(agrupamento);

    const gerentesVisiveis = gerenteSelecionado
        ? [gerenteSelecionado]
        : gerentes;

    const somaCadastros = gerentesVisiveis.reduce((acc, gerente) => acc + gerente.dadosAnalitico.cadastrosRealizados, 0);
    const somaConversoes = gerentesVisiveis.reduce((acc, gerente) => acc + gerente.dadosAnalitico.indConversao, 0);
    const mediaConversao = gerentesVisiveis.length > 0 ? somaConversoes / gerentesVisiveis.length : 0;
    const somaContestoes = gerentesVisiveis.reduce((acc, gerente) => acc + gerente.dadosAnalitico.contestacoes, 0);

    const responsive = {
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    };

    const removerFiltro = () => setGerenteSelecionado(null);

    return (
      <>
<Row>
  {!gerenteSelecionado ? (
    <>
      <Row>
        <Col sm={12} md={4}>
          <Card>
            <Card.Body>
              <Row>
                <Col className="d-flex justify-content-start">
                  <FontAwesomeIcon icon={faPeopleRoof} />
                  <span style={{ marginLeft: 5 }}>Novos Cadastros:</span>
                </Col>
                <Col className="d-flex justify-content-end">{somaCadastros}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Body>
              <Row>
                <Col className="d-flex justify-content-start">
                  <FontAwesomeIcon icon={faChartLine} />
                  <span style={{ marginLeft: 5 }}>Ind. Conversão:</span>
                </Col>
                <Col className="d-flex justify-content-end">
                  {mediaConversao.toFixed(2)}%
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card>
            <Card.Body>
              <Row>
                <Col className="d-flex justify-content-start">
                  <FontAwesomeIcon icon={faBriefcase} />
                  <span style={{ marginLeft: 5 }}>Contestações:</span>
                </Col>
                <Col className="d-flex justify-content-end">{somaContestoes}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="form-check form-switch d-flex justify-content-end mt-4">
        <input
          className="form-check-input"
          type="checkbox"
          id="switchAgrupamento"
          checked={agrupamento === "filial"}
          onChange={() => {
            removerFiltro(); // Limpa o filtro ao mudar o agrupamento
            setAgrupamento((prev) =>
              prev === "regiao" ? "filial" : "regiao"
            );
          }}
        />
        <label className="form-check-label" htmlFor="switchAgrupamento">
          Agrupar por {agrupamento === "regiao" ? "Filial" : "Região"}
        </label>
      </div>
      {Object.entries(dadosAgrupados).map(([chave, gerentes]) => (
        <div key={chave} className="mt-5 mb-5">
             {!gerenteSelecionado || gerentes.some((g) => g.codigoGerente === gerenteSelecionado?.codigoGerente) ? (

            <>
              <h3 className="mb-4">{chave}</h3>
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
                customTransition="all 0.5s"
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {gerentes
                  .filter(
                    (gerente) =>
                      !gerenteSelecionado ||
                      gerente.codigoGerente === gerenteSelecionado
                  )
                  .map((gerente) => (
                    <Card
                      key={gerente.codigoGerente}
                      className="text-center selected"
                      style={{
                        margin: "0 10px",
                        padding: "20px",
                        borderRadius: "15px",
                      }}
                      onClick={() => setGerenteSelecionado(gerente)}
                    >
                      <Card.Img
                        variant="top"
                        src={gerente.avatar}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          margin: "0 auto 15px",
                        }}
                      />
                      <Card.Body>
                        <Card.Title>{gerente.nomeGerente}</Card.Title>
                      </Card.Body>
                      <Card.Footer>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Produção</th>
                              <th>Meta</th>
                              <th>Atingimento</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                R${" "}
                                {gerente.producaoDados.valBruto.toLocaleString()}
                              </td>
                              <td>
                                R${" "}
                                {gerente.producaoDados.meta.toLocaleString()}
                              </td>
                              <td>
                                {(
                                  (gerente.producaoDados.valBruto /
                                    gerente.producaoDados.meta) *
                                  100
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Footer>
                    </Card>
                  ))}
              </Carousel>
            </>
          ) : null}
        </div>
      ))}
    </>
  ) : (
    <Row className="align-items-start">
      <Col sm={6}>
        <Card
          className="text-center selected"
          style={{
            margin: "0 auto",
            padding: "20px",
            borderRadius: "15px",
          }}
        >
          <Card.Img
            variant="top"
            src={gerenteSelecionado.avatar}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              margin: "0 auto 15px",
            }}
          />
          <Card.Body>
            <Card.Title>{gerenteSelecionado.nomeGerente}</Card.Title>
          </Card.Body>
          <Card.Footer>
            <Table striped hover>
              <thead>
                <tr>
                  <th>Produção</th>
                  <th>Meta</th>
                  <th>Atingimento</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    R${" "}
                    {gerenteSelecionado.producaoDados.valBruto.toLocaleString()}
                  </td>
                  <td>
                    R${" "}
                    {gerenteSelecionado.producaoDados.meta.toLocaleString()}
                  </td>
                  <td>
                    {(
                      (gerenteSelecionado.producaoDados.valBruto /
                        gerenteSelecionado.producaoDados.meta) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Footer>
        </Card>
      </Col>
      <Col sm={6}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="extrato">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="extrato">Extrato de Comissão</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Projeção de Produção</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="extrato">
              <h4>Extrato de Comissão</h4>
              <p>{gerenteSelecionado.nomeGerente}</p>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <h4>Projeção de Produção</h4>
              <p>Produção prevista para {gerenteSelecionado.nomeGerente}</p>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  )}
</Row>;


      </>
    );
};

export default ListManagersRegional;
