import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  LabelList,
} from "recharts";
import { Form, Button, Row, Col, Container, Table, Alert } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./ComparativoMesAno.scss";

export default function ComparativoMesAno() {
  const [analysisType, setAnalysisType] = useState<"mensal" | "semanal">("mensal");
  const [selections, setSelections] = useState<string[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [checkedSelections, setCheckedSelections] = useState<string[]>([]);
  const [growthAlert, setGrowthAlert] = useState<string | null>(null);
  const [selectedPartner, setSelectedPartner] = useState(localStorage.getItem("selectedPartner"));
  const codSupervisor = localStorage.getItem("codSupervisor");

  const handleAddSelection = () => {
    setSelections([...selections, ""]);
  };

  const handleRemoveSelection = (index: number) => {
    const updatedSelections = selections.filter((_, i) => i !== index);
    setSelections(updatedSelections);
    setChartData((prevData) => prevData.filter((_, i) => i !== index)); // Remove o dado do gráfico também
  };

  const handleChangeSelection = (index: number, value: string) => {
    const updatedSelections = [...selections];
    updatedSelections[index] = value;
    setSelections(updatedSelections);
  };

  const handleCheckboxChange = (selection: string) => {
    const updatedCheckedSelections = [...checkedSelections];
    if (updatedCheckedSelections.includes(selection)) {
      // Remover a seleção
      setCheckedSelections(updatedCheckedSelections.filter((s) => s !== selection));
      // Limpar os alerts se uma das seleções for removida
      setGrowthAlert(null);
    } else if (updatedCheckedSelections.length < 2) {
      // Adicionar a seleção se houver espaço
      updatedCheckedSelections.push(selection);
      setCheckedSelections(updatedCheckedSelections);
    }
  };

  // Atualiza o selectedPartner e busca dados
  useEffect(() => {
    const interval = setInterval(() => {
      const storedPartner = localStorage.getItem("selectedPartner");
      if (storedPartner !== selectedPartner) {
        setSelectedPartner(storedPartner);
      }
    }, 1000); // Verifica a cada 1 segundo

    return () => {
      clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    };
  }, [selectedPartner]);

  const fetchChartData = async () => {
    try {
      const responses = await Promise.all(
        selections.map(async (selection) => {
          if (!selection) return null;

          const codProdutorVenda = selectedPartner;
          const baseUrl =
            analysisType === "mensal"
              ? `https://bi.gfttech.com.br/api/v2/gerentes/DigitacaoMensal?codSupervisor=${codSupervisor}&mesAno=${selection}`
              : `https://bi.gfttech.com.br/api/v2/gerentes/DigitacaoSemanal?codSupervisor=${codSupervisor}&base=${selection.split(".")[0]}&semana_do_mes=${selection.split(".")[1]}`;

          const url = codProdutorVenda ? `${baseUrl}&codProdutorVenda=${codProdutorVenda}` : baseUrl;

          const response = await axios.get(url);
          return { selection, data: response.data };
        })
      );

      const aggregatedData = responses
        .filter(Boolean)
        .map(({ selection, data }: any) => {
          const totalBruto = data.reduce((sum: number, item: any) => sum + item.total_bruto, 0);
          return {
            name: selection,
            total_bruto: totalBruto,
          };
        });

      setChartData(aggregatedData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    if (selectedPartner) {
      fetchChartData();
    }
    if (selections.some((s) => s)) {
      fetchChartData();
    }
  }, [selectedPartner, selections, analysisType]);

  const getAvailableOptions = () => {
    const options = [];
    const startYear = 2024;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    for (let year = startYear; year <= currentYear; year++) {
      const maxMonth = year === currentYear ? currentMonth : 12;
      for (let month = 1; month <= maxMonth; month++) {
        const formattedMonth = month.toString().padStart(2, "0");
        options.push(`${year}${formattedMonth}`);
        if (analysisType === "semanal") {
          for (let week = 1; week <= 4; week++) {
            options.push(`${year}${formattedMonth}.${week}`);
          }
        }
      }
    }
    return options;
  };

  const formatValue = (value: number): string => {
    if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}K`;
    }
    return value.toString();
  };
  const formatValueTab  = (value: number): string => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const compareGrowth = (data: any[]) => {
    const sortedData = [...data].sort((a, b) => b.total_bruto - a.total_bruto);
    return sortedData.map((current, index) => {
      if (index === sortedData.length - 1) {
        return { ...current, comparison: "-" };
      }
      const next = sortedData[index + 1];
      const growth = ((current.total_bruto - next.total_bruto) / next.total_bruto) * 100;
      return { ...current, comparison: `${growth.toFixed(2)}%` };
    });
  };

  const calculateGrowthComparison = () => {
    if (checkedSelections.length === 2) {
      const [selection1, selection2] = checkedSelections;
      const data1 = chartData.find((item) => item.name === selection1);
      const data2 = chartData.find((item) => item.name === selection2);
  
      if (data1 && data2) {
        // Compare the two total_bruto values to determine which is larger
        const growth = ((Math.max(data1.total_bruto, data2.total_bruto) - Math.min(data1.total_bruto, data2.total_bruto)) / Math.min(data1.total_bruto, data2.total_bruto)) * 100;
        
        // Determine which selection is greater
        if (data1.total_bruto > data2.total_bruto) {
          setGrowthAlert(`A digitação de ${selection1} é ${growth.toFixed(2)}% maior do que ${selection2}.`);
        } else if (data2.total_bruto > data1.total_bruto) {
          setGrowthAlert(`A digitação de ${selection2} é ${growth.toFixed(2)}%  maior do que  ${selection1}.`);
        } else {
          // If both values are equal
          setGrowthAlert(`Os valores para ${selection1} e ${selection2} são iguais.`);
        }
      }
    } else {
      setGrowthAlert(null);
    }
  };
  

  useEffect(() => {
    calculateGrowthComparison();
  }, [checkedSelections, chartData]);

  const comparedData = compareGrowth(chartData);

  return (
    <Container className="comparativo-mes-ano">
      <h3 className="mb-4">Comparativo Mensal e Semanal</h3>
      <Form>
        <Form.Group className="mb-4">
          <Form.Label>Tipo de Análise</Form.Label>
          <Form.Select
            value={analysisType}
            onChange={(e) => {
              setAnalysisType(e.target.value as "mensal" | "semanal");
              setSelections([]);  // Resetando as seleções ao trocar o tipo de análise
              setChartData([]); 
              setCheckedSelections([]);
            }}
          >
            <option value="mensal">Mensal</option>
            <option value="semanal">Semanal</option>
          </Form.Select>
        </Form.Group>

        {selections.map((selection, index) => (
          <Row key={index} className="mt-3 mb-3">
            <Col md={10} sm={8}>
              <Form.Select
                value={selection}
                onChange={(e) => handleChangeSelection(index, e.target.value)}
              >
                <option value="">Selecione...</option>
                {getAvailableOptions().map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={2} sm={4}>
              <Button variant="danger" onClick={() => handleRemoveSelection(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Col>
          </Row>
        ))}

        <Button variant="primary" onClick={handleAddSelection} className="mb-4">
          Adicionar Seleção
        </Button>
      </Form>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={chartData}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tickFormatter={formatValue} />
          <YAxis type="category" dataKey="name" />
          <Tooltip formatter={(value) => formatValue(value as number)} />
          <Bar dataKey="total_bruto" fill="#062173">
            <LabelList dataKey="total_bruto" position="insideRight" fill="#FFFFFF" formatter={formatValue} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Mês/Semana</th>
            <th>Produção</th>
            <th>Comparativo</th>
          </tr>
        </thead>
        <tbody>
          {comparedData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{formatValueTab(item.total_bruto)}</td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={checkedSelections.includes(item.name)}
                  onChange={() => handleCheckboxChange(item.name)}
                  disabled={checkedSelections.length >= 2 && !checkedSelections.includes(item.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {growthAlert && (
        <Alert variant="success" className="mt-3">
          {growthAlert}
        </Alert>
      )}
    </Container>
  );
}
