import { Card } from "react-bootstrap";
import styles from '../CardsProjecao.module.scss';

interface MetaDiariaRestanteProps {
  valorMetaDiaria: number;
}


const MetaDiariaRestante: React.FC<MetaDiariaRestanteProps> = ({ valorMetaDiaria }) => {
  if(!valorMetaDiaria) valorMetaDiaria = 0;
  return (
    <Card>
      <Card.Header>
        <h5 className={`${styles.titleCard} display-6`}>
          <b>Meta Diária Restante</b>
        </h5>
      </Card.Header>
      <Card.Body>
        <div className={styles.cardContent}>
          R$ {valorMetaDiaria.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
        </div>
      </Card.Body>
    </Card>
  );
}

export default MetaDiariaRestante;
